.Topheader {
  top: 0;
  position: sticky;
  background-color: #fff;
  padding-top: 5px;
  padding-bottom: 5px;
  max-height: 65px;
}

.search_mobile svg {
  fill: currentColor;
}

.take_quiz {
  color: #ff8a00;
  white-space: no-wrap;
  justify-content: center;
}

.search_mobile {
  display: none;
}

.busines-school-image-set {
  position: relative;
}

.headerImg {
  max-width: 200px;
}

input:-internal-autofill-selected {
  background-color: #ffffff !important;
  background: #ffffff !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.login .form-group input:focus {
  background-color: #fff;
}

.mobile-navbar {
  display: none;
}

.acc_title {
  font-size: 18px;
  line-height: 18px;
}

p {
  font-size: 16px !important;
  line-height: 24px !important;
  font-weight: 400 !important;
}

.header2 ul {
  display: flex;
  height: 40px;
  background-color: #1d1e3c;
  justify-content: space-evenly;
  align-items: center;
  color: white !important;
  font-size: 13px;
  margin-bottom: -60px;
  list-style: none;
}

.header2 ul li {
  cursor: pointer;
  transition: color 0.3s ease border 0.3s ease;
  border: 1px solid transparent;
  border-radius: 3px;
  padding: 5px;
}

.header2 ul li:hover {
  color: white !important;
  border: 1px solid white;
  border-radius: 3px;
  padding: 5px;
}

.header2 ul li a {
  color: inherit !important;
}

.navbar-styles {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.navBarFirst {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 160px;
}

.navbarItems ul {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 70px;
  list-style: none;
  margin-top: 17px;
}

.navbarItems ul li {
  color: #1d1e3c;
  text-align: center;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.navbarItems ul li a {
  text-decoration: none !important;
  color: #1d1e3c;
  transition: color 0.3s ease;
}

.navbarItems ul li a:hover {
  color: #f47896;
}

.navbarItems ul li a.active {
  color: #f47896;
}

.homeTop {
  background-color: white;
  margin-top: 70px;
  z-index: -1;
  min-height: 640px;
}

.homeIcons {
  position: relative;
}

.homeIcons::before {
  content: "";
  position: absolute;
  top: 45px;
  left: 236px;
  width: 30px;
  height: 30px;
  background-image: url("../src/assets/images/homeVector1.svg");
  background-size: cover;
  /* z-index: -1; */
}

.homeIcons::after {
  content: "";
  position: absolute;
  top: 60px;
  right: 20px;
  width: 30px;
  height: 30px;
  background-repeat: repeat;
  background-image: url("../src/assets/images/homeVector2.svg");
  background-size: cover;
  /* z-index: -1; */
}

.homeContent {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 20px;
  gap: 20px;
}

.homeContent h1 {
  color: #1d1e3c;
  text-align: center;
  font-family: "DM Sans";
  font-size: 60px;
  font-style: normal;
  font-weight: 700;
  line-height: 70px;
}

.homeContent p {
  color: #9ca3af;
  text-align: center;
  font-family: "DM Sans";
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  margin-left: 10%;
  line-height: 18px;
  margin-right: 10%;
}

.input-wrapper {
  position: relative;
  width: 100%;
  max-width: 655px;
}

.inputBar {
  min-width: 100%;
  min-height: 55px;
  padding-left: 15px;
  border: none;
  color: #000000;
  font-family: "DM Sans";
  font-size: 14px;
  box-shadow: 6px 6px 30px 0px rgba(30, 30, 30, 0.1);
  font-style: normal;
  font-weight: 400;
  border-radius: 100px;
  padding-right: 120px;
}

.inputBar::placeholder {
  color: #9ca3af;
}

.inputBar:focus {
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px !important;
  color: black;
}

.errorMessage {
  font-size: 13px;
  margin-top: 5px;
  color: red;
}

.inputButton {
  position: absolute;
  top: 4px;
  right: 4px;
  border: none;
  padding: 10px 40px 10px 40px;
  border-radius: 100px;
  color: #fff;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  border: 2px solid #20776e;
  font-weight: 500;
  background: #20776e;
  box-shadow: 6px 6px 20px 0px rgba(30, 30, 30, 0.1);
}

.inputButton:hover {
  background-color: transparent;
  color: #20776e;
  border-color: #20776e;
}

.homeSecond {
  background-image: url("../src/assets/images/homeSecond.png");
  height: 520px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 0;
  top: -204px;
  position: relative;
}

.homeSecond::before {
  content: "";
  position: absolute;
  top: -12px;
  left: 150px;
  width: 30px;
  height: 30px;
  background-image: url("../src/assets/images/homeVector2.svg");
  background-size: cover;
  /* z-index: -1; */
}

.homeSecond::after {
  content: "";
  position: absolute;
  top: 10px;
  right: 76px;
  width: 30px;
  height: 30px;
  background-repeat: repeat;
  background-image: url("../src/assets/images/homeVector1.svg");
  background-size: cover;
  /* z-index: -1; */
}

.homeSecond .imagesSet {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 30px;
}

.imagesSet .images {
  max-width: 230px;
  /* box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px; */
  border-radius: 115px;
}

.section-two {
  position: relative;
  top: -140px;
}

.whyChoose {
  position: relative;
}

.whyChoose::before {
  content: "";
  position: absolute;
  top: 100px;
  right: 300px;
  width: 600px;
  height: 387px;
  background-image: url("../src/assets/images/whyChoose.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.whychoose-set1 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 100px;
}

.whychoose-set1 h1 {
  color: #1d1e3c;
  font-family: "DM Sans";
  font-size: 44px;
  font-style: normal;
  font-weight: 700;
  line-height: 55px;
}

.whychoose-set2 {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 240px;
  flex-direction: column;
}

.whychoose-set2 .whychoose-section {
  display: flex;
  justify-content: end;
  align-items: end;
  gap: 15px;
  width: 100%;
  flex-direction: column;
}

.whychoose-section {
  color: #9ca3af;
  text-align: center;
  font-family: "DM Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.whychoose-section p {
  margin-left: 30%;
}

.whychoose-section-2 {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 30px;
}

.whychoose-section-2 .course-type .cardveiw {
  width: 300px;
}

.course-type {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}

.course-type h3 {
  color: #1d1e3c;
  text-align: center;
  font-family: "DM Sans";
  font-size: 20px !important;
  font-style: normal;
  font-weight: 700;
}

.course-type p {
  color: #9ca3af;
  text-align: center;
  font-family: "DM Sans";
  font-size: 16px !important;
  font-style: normal;
  font-weight: 400;
}

.animated {
  animation: up-down 2s ease-in-out infinite alternate-reverse both;
}

.dynamic-course {
  position: relative;
}

.dynamic-course::before {
  content: "";
  position: absolute;
  top: 89px;
  right: 533px;
  width: 76px;
  height: 64px;
  background-image: url("../src/assets//images/dynamicCourse.svg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.dynamic-course-section {
  display: flex;
  position: relative;
  top: -28px;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.explore-section h3 {
  color: #1d1e3c;
  font-family: DM Sans;
  font-size: 43px;
  font-style: normal;
  font-weight: 700;
  line-height: 55px;
}

.explore-section p {
  color: #9ca3af;
  font-family: DM Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  /* padding-right: 45%; */
  line-height: 20px;
}

.course-Showing {
  color: rgba(206, 138, 36, 0.1);
  text-align: right;
  font-family: "DM Sans";
  font-size: 115px;
  font-style: normal;
  font-weight: 700;
  line-height: 80px;
}

.course-card {
  min-height: 360px;
  width: 100%;
  border-radius: 12px;
  border: 1px solid #d9d9d9;
  background: rgba(255, 255, 255, 0.92);
  padding: 12px;
}

.course-card .course-image {
  width: 100%;
  height: 165px;
  border-radius: 12px;
  object-fit: cover;
}

.tableBox {
  overflow: auto;
  width: 100%;
}


.couserTypeLogo {
  position: absolute;
  top: 123px;
  left: 12px;
  font-size: 12px;
  padding: 2px 10px;
  color: #20776e;
  font-weight: 700;
  background: #f6f7fb;
  border-radius: 9px;
  line-height: 18px;
}

.course-details {
  display: flex;
  margin-top: 10px;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}

.course-details .level {
  padding: 2px 4px;
  font-size: 12px;
  border-radius: 6px;
  background-color: #fafafa;
}

.course-details h3 {
  color: #1d1e3c;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.course-details p {
  color: #9ca3af;
  font-family: "DM Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  width: 100%;
  line-height: normal;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: normal;
}

.course-ratings {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  gap: 8px;
  width: 100%;
  margin: 5px 0;
  flex-wrap: wrap;
}

.course-ratings .courseIcon {
  width: 20px;
  height: 20px;
}

.course-ratings .ratingPoint {
  color: #1d1e3c;
  font-family: DM Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.course-ratings .userName {
  color: #1d1e3c;
  font-family: "DM Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.course-ratings .userImage {
  height: 35px;
  width: 35px;
  border-radius: 50px;
}

i.bi {
  font-size: 16px;
  color: #20776e;
  cursor: pointer;
}

.course-ratings .bi {
  font-size: 14px;
}

.guides-section {
  background-color: #f6f6f6;
  padding: 20px;
  border-radius: 20px;
}

.course-ratings .courseUsers {
  color: #9ca3af;
  font-family: DM Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

a {
  text-decoration: none !important;
}

.navContent ul li a {
  color: inherit !important;
}

.newDesignBtn {
  border-radius: 65px;
  min-height: 35px !important;
  height: 35px !important;
  background: #20776e;
  box-shadow: 6px 6px 20px 0px rgba(30, 30, 30, 0.1);
  display: flex;
  padding: 10px 35px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  color: #fff;
  border: 2px solid #20776e;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  transition: background-color 0.3s ease, color 0.3s ease;
  min-width: 145px;
  line-height: normal;
}

.newDesignBtn:hover {
  background-color: white;
  border: 2px solid #20776e;
  color: #20776e;
}

.HomeBig-section {
  display: flex;
  width: 100%;
  height: 699px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.HomeBigtext {
  color: #202e77;
  text-align: center;
  font-family: "DM Sans";
  font-size: 150px;
  font-style: normal;
  font-weight: 700;
  line-height: 190px;
}

.HomeBigtext span {
  color: #f47896;
  font-family: "DM Sans";
  font-size: 150px;
  font-style: normal;
  font-weight: 700;
  line-height: 190px;
}

.busines-school-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 60px;
}

.for-text {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  position: relative;
  gap: 14px;
  width: 50%;
}

.for-text::before {
  content: "";
  position: absolute;
  top: 52px;
  left: 300px;
  width: 100%;
  height: 60px;
  background-image: url("../src/assets/images/typeImage.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.for-text-2 {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  position: relative;
  gap: 14px;
}

.for-text-2::before {
  content: "";
  position: absolute;
  top: 22px;
  left: 290px;
  width: 100%;
  height: 60px;
  background-image: url("../src/assets/images/typeImage.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.for-text h3 {
  color: #1d1e3c;
  font-family: "DM Sans";
  font-size: 43px;
  font-style: normal;
  font-weight: 700;
  line-height: 58px;
}

.for-text p {
  color: #9ca3af;
  font-family: "DM Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}

.for-text-2 h3 {
  color: #1d1e3c;
  font-family: "DM Sans";
  font-size: 43px;
  font-style: normal;
  font-weight: 700;
  line-height: 58px;
}

.for-text-2 p {
  color: #9ca3af;
  font-family: "DM Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}

.busines-school-image-set {
  margin-top: 40px;
}

.busines-school-image-set .image {
  width: 100%;
}

.busines-school-image-set .text {
  position: absolute;
  top: 0;
  left: 15px;
  right: 15px;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-family: "DM Sans";
  font-size: 35px;
  font-weight: 500;
  line-height: 45px;
  text-align: center;
}

.happy-student-section {
  background-color: #fff;
  height: auto;
  width: 100%;
  padding: 60px;
}

.happy-student-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
}

.happy-student-text h3 {
  color: #1d1e3c;
  text-align: center;
  font-family: "DM Sans";
  font-size: 44px;
  font-style: normal;
  font-weight: 700;
  line-height: 58px;
}

.happy-student-text p {
  color: #9ca3af;
  text-align: center;
  font-family: DM Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.happy-student-card {
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 15px;
}

.reviewCard {
  padding: 15px;
  width: 235px;
  border-radius: 9px;
  /* height: 181px; */
  border: 1px solid lightgray;
}

.user {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
}

.reviewCard .user .user-image {
  height: 45px;
  width: 45px;
  border-radius: 50px;
  object-fit: cover;
}

.user .name-course {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}

.name-course .user-name {
  color: #1d1e3c;
  font-family: "DM Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.allreviewsection {
  margin-top: 90px;
}

.name-course .course-name {
  color: #f2a227;
  font-family: "DM Sans";
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.seeRevewis {
  margin-top: 10px;
  margin-bottom: 30px;
  color: #1d1e3c;
  font-size: 14px;
  font-weight: 500;
}

.reviewCard .para {
  color: #9ca3af;
  font-family: "DM Sans";
  font-size: 14px;
  font-style: italic;
  margin-top: 10px;
  font-weight: 400;
  line-height: 20px;
}

.stay-update-section {
  margin-top: 60px;
  padding: 60px;
  background: linear-gradient(180deg,
      rgba(32, 119, 110, 0.1) 0%,
      rgba(32, 119, 110, 0) 100%);
}

.footerLogo {
  width: 100%;
}

.bg-stay-updated {
  position: relative;
}

.bg-stay-updated::before {
  content: "";
  position: absolute;
  top: -7px;
  right: 327px;
  width: 331px;
  height: 450px;
  background-image: url("../src//assets//images//stayUpdated.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.stay-updated-text {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 80px;
  flex-direction: column;
  gap: 30px;
}

.stay-updated-text .stayupdateImage {
  max-width: 230px;
}

.stay-updated-text h1 {
  color: #1d1e3c;
  font-family: "DM Sans";
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
}

.stay-updated-text p {
  color: #9ca3af;
  font-family: "DM Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.stayupdated-input {
  text-align: center;
  border: none;
  background: transparent;
  border-bottom: 1px solid lightgray;
  height: 40px;
  font-size: 14px;
  width: 304px;
}

.stay-updated-image {
  text-align: center;
}

/* .stay-updated-image .stayUpdated2 {
  max-width: 500px;
} */

.acc-section {
  margin-top: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.acc-section h3 {
  color: #1d1e3c;
  text-align: center;
  font-family: DM Sans;
  font-size: 33px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.acc-section p {
  color: #9ca3af;
  text-align: center;
  font-family: DM Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.acc-styles {
  padding: 15px 36px !important;
  color: #1d1e3c;
  font-family: "DM Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

}

.accordian-body-styles {
  background: transparent !important;
  border: 1px solid lightgray !important;
  border-radius: 10px !important;
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}

.accordion-button:not(.collapsed) {
  color: #f47896 !important;
  background-color: transparent;
}

.accordion-body {
  padding: 20px 0px !important;
}

.acc-para {
  color: #9ca3af;
  font-family: "DM Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  padding-left: 35px !important;
  padding-right: 35px !important;
}

.accordion-button::after {
  background-image: url("../src/assets/images/plus-icon.png") !important;
}

.accordion-button:not(.collapsed)::after {
  background-image: url("../src/assets/images/minus-icon.png") !important;
}

.mobile-login {
  padding: 4px;
  border-radius: 10px;
  border: 1px solid transparent;
  font-weight: 600;
  color: #20776e;
}

.mobile-login:hover {
  padding: 4px;
  border-radius: 10px;
  border: 1px solid #20776e;
}

.mobile-offcanvas {
  width: 75% !important;
}

.about-us-section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 40px;
  margin-top: 120px;
}

.about-us-text h1 {
  color: #1d1e3c;
  text-align: center;
  font-family: DM Sans;
  font-size: 55px;
  font-style: normal;
  font-weight: 700;
  line-height: 60px;
}

.about-us-text p {
  color: #9ca3af;
  text-align: center;
  font-family: DM Sans;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.aboutFirst {
  max-width: 500px;
}

.best-feild {
  position: relative;
  height: 300px;
  margin-top: 90px;
}

.best-feild::before {
  content: "";
  position: absolute;
  top: 13px;
  left: 340px;
  width: 204px;
  height: 124px;
  background-image: url("../src/assets/images/aboutSecond.png");
  background-size: cover;
  /* z-index: -1; */
}

.best-field-heading {
  height: auto;
}

.best-field-heading h1 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #1d1e3c;
  font-family: "DM Sans";
  font-size: 40px;
  flex-direction: row;
  font-style: normal;
  font-weight: 700;
  line-height: 54px;
}

.best-field-para {
  height: 171px;
  display: flex;
  align-items: flex-end;
}

.best-field-para p {
  flex-direction: row;
  color: #9ca3af;
  font-family: "DM Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.about-courses-bg {
  background-color: white;
  height: auto;
  align-items: center;
  padding: 60px;
  display: flex;
}

.about-courses ul {
  display: flex;
  list-style: none;
  justify-content: space-evenly;
  align-items: center;
  gap: 35px;
  flex-wrap: wrap;
}

.about-courses ul li {
  color: #f47896;
  margin-top: 15px;
  font-family: "DM Sans";
  font-size: 15px;
  border-right: 1px ridge #ececec;
  font-style: normal;
  font-weight: 700;
  line-height: 23px;
  padding-right: 50px;
}

.course-selection-section {
  position: relative;
  margin-top: 50px;
  height: auto;
}

.course-selection-section::before {
  content: "";
  position: absolute;
  top: 428px;
  left: 621px;
  width: 200px;
  height: 169px;
  background-image: url("../src/assets/images/courseSelection.png");
  background-size: cover;
  /* z-index: -1; */
}

.course-selection-item {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 80px;
}

.course-selection-text {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  margin-top: 100px;
  gap: 50px;
}

.course-selection-text h3 {
  color: #1d1e3c;
  font-family: "DM Sans";
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  text-align: left;
  line-height: 45px;
  max-width: 330px;
}

.course-selection-text p {
  color: #9ca3af;
  font-family: "DM Sans";
  font-size: 13px;
  text-align: left;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.ready-start-bg {
  height: 400px;
  border-radius: 30px;
  background-color: rgba(32, 119, 110, 0.1);
  margin-top: 70px;
  margin-bottom: 110px;
  position: relative;
}

.ready-start-section {
  display: flex;
  justify-content: center;
  height: 400px;
  align-items: center;
  flex-direction: column;
  gap: 50px;
  padding: 15px;
}

.topStartCr::before {
  content: "";
  position: absolute;
  top: 0;
  left: 24px;
  width: 180px;
  height: 84px;
  background-image: url("../src/assets/images/topStartCr.png");
  background-size: cover;
  /* z-index: -1; */
}

.topStart::after {
  content: "";
  position: absolute;
  top: 0;
  right: -6px;
  width: 214px;
  height: 121px;
  background-image: url("../src/assets/images/topStart.png");
  background-size: cover;
  /* z-index: -1; */
}

.bottomStart::before {
  content: "";
  position: absolute;
  top: 278px;
  left: 2px;
  width: 180px;
  height: 121px;
  background-image: url("../src/assets/images/bottomStart.png");
  background-size: cover;
  /* z-index: -1; */
}

.bottomStartCr::after {
  content: "";
  position: absolute;
  top: 310px;
  right: 56px;
  width: 190px;
  height: 89px;
  background-image: url("../src/assets/images/bottomStartCr.png");
  background-size: cover;
  /* z-index: -1; */
}

.ready-start-section h1 {
  color: #1d1e3c;
  text-align: center;
  font-family: "DM Sans";
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 50px;
}

.ready-start-section p {
  color: #9ca3af;
  text-align: center;
  font-family: "DM Sans";
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.school-top-section {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 110px;
  flex-direction: column;
  gap: 35px;
}

.school-top-section h1 {
  color: #1d1e3c;
  text-align: center;
  font-family: "DM Sans";
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  line-height: 54px;
}

.school-top-section p {
  color: #9ca3af;
  text-align: center;
  font-family: "DM Sans";
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.schoolvideo {
  width: 100%;
}

.videoSchool {
  position: relative;
}

/* YourButtonStyles.css */

.VideoBtn {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  border: none;
  font-size: 35px;
  transform: translate(-50%, -50%);
  background: white;
  border: 1px solid #20776e;
  color: #20776e;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease-in-out, color 0.3s ease-in-out;
  /* Add transition properties */
}

.VideoBtn.playing {
  transform: translate(-50%, -50%) scale(1.2);
  /* Scale up when playing */
  color: #20776e;
  /* Change color when playing */
}

.VideoBtn.paused {
  transform: translate(-50%, -50%) scale(1);
  /* Restore original scale when paused */
  color: #20776e;
  /* Change color when paused */
}

.benefits-section {
  margin-bottom: 40px;
  display: flex;
  justify-content: center;
  /* align-items: center; */
  flex-direction: row;
  flex-wrap: wrap;
  gap: 30px;
}

.benefitsCard {
  padding: 30px;
  background-color: white;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 330px;
  /* height: 340px; */
  justify-content: flex-start;
  align-items: center;
}

.benefitsCard h1 {
  color: #1d1e3c;
  font-family: DM Sans;
  font-size: 18px !important;
  line-height: 5px;
  font-style: normal;
  text-align: center;
  line-height: 26px !important;
  font-weight: 700;
}

.benefitsCard p {
  color: #9ca3af;
  font-family: DM Sans;
  font-size: 14px;
  text-align: center;
  font-style: normal;
  font-weight: 300;
  line-height: 22px;
}

.callAction h1 {
  color: #1d1e3c;
  font-family: DM Sans;
  font-size: 35px;
  line-height: 5px;
  font-style: normal;
  font-weight: 700;
}

.callAction p {
  color: #9ca3af;
  font-family: DM Sans;
  font-size: 14px;
  text-align: left;
  margin-top: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

.imageCollage {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 8px;
}

.world-section-bg {
  background-color: white;
  height: auto;
  padding: 70px;
  margin-top: 70px;
  display: flex;
  align-items: center;
}

.world-section-text h3 {
  color: #1d1e3c;
  font-family: "DM Sans";
  font-size: 50px;
  font-style: normal;
  border-right: 1px dashed #dbdbdb;
  font-weight: 700;
  line-height: 65px;
  margin: 0;
}

.world-section-text .showWork {
  display: none;
}

.world-small-text {
  display: flex;
  justify-content: center;
  /* align-items: center; */
  /* height: 200px; */
  flex-direction: row;
  /* gap: 40px; */
}

.world-small-text h3 {
  color: #1d1e3c;
  text-align: center;
  font-family: DM Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
}

.world-small-text p {
  color: #9ca3af;
  text-align: center;
  font-family: DM Sans;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

.dropdown-menu {
  z-index: 9999;
  border-radius: 0px !important;
}

.dropdown-item {
  font-size: 14px;
}

.dropdown-item:active {
  background-color: lightgrey !important;
  font-size: 14px;
  font-weight: 500;
  color: black !important;
}

.offcanvas-header {
  padding: 7px !important;
}

.offcanvas-body {
  padding: 10px !important;
  margin-top: 4px !important;
}

.mobile-sidebar {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
}

.navContent ul {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  list-style: none;
  gap: 20px;
  padding: 0;
}

.navContent ul li {
  font-size: 14px;
  color: rgb(102, 97, 97);
  font-weight: 500;
}

.contact-section {
  height: 105vh;
}

.contact-section-bg::before {
  content: "";
  position: absolute;
  width: 47%;
  z-index: -1;
  background-color: #f47896;
  height: 105vh;
}

.content-contact {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  height: 100vh;
  gap: 260px;
}

.content-contact .left-text {
  color: white;
  font-family: "DM Sans";
  font-size: 60px;
  font-style: normal;
  font-weight: 500;
  line-height: 70px;
}

.firstCirlce {
  position: relative;
}

.firstCirlce::before {
  content: "";
  position: absolute;
  top: -80px;
  left: -15px;
  width: 170px;
  height: 170px;
  background-image: url("../src/assets/images/CircleDash.png");
  background-size: cover;
  /* z-index: -1; */
}

.firstSecond {
  position: relative;
}

.firstSecond::before {
  content: "";
  position: absolute;
  top: -80px;
  left: 100px;
  width: 170px;
  height: 170px;
  background-image: url("../src/assets/images/CircleSimple.png");
  background-size: cover;
  /* z-index: -1; */
}

.firstThird {
  position: relative;
}

.firstThird::before {
  content: "";
  position: absolute;
  top: -80px;
  left: 220px;
  width: 170px;
  height: 170px;
  background-image: url("../src/assets/images/CircleDash.png");
  background-size: cover;
  /* z-index: -1; */
}

.contact-text-boxs {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 15px;
  flex-direction: column;
}

.allreviewscard {
  padding: 10px 10px 10px 10px;
  height: auto;
  width: 100%;
  border-radius: 20px;
}


.contact-text-boxs h1 {
  color: #1d1e3c;
  font-family: DM Sans;
  font-size: 45px;
  font-style: normal;
  margin-top: 100px;
  font-weight: 400;
  line-height: 50px;
}

.contact-text-boxs span {
  color: #1d1e3c;
  font-family: DM Sans;
  font-size: 45px;
  font-style: normal;
  font-weight: 700;
  line-height: 50px;
}

.input-set {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 5px;
  flex-direction: column;
}

.contactForm {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 30px;
  align-items: flex-start;
  justify-content: flex-start;
}

.input-set .heading {
  color: #3f3f44;
  font-family: "DM Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

/* .input-set .inputContact input {
  color: #9a9a9d;
  font-family: "DM Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 210px;
  height: 37px;
  border: 1px solid #f1f0f0;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 6px;
} */
/* .input-set .inputContact textarea {
  color: #9a9a9d;
  font-family: "DM Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 445px;
  height: 147px;
  border: none;
  padding: 8px 15px 8px 15px;
  border-radius: 6px;
} */
textarea {
  resize: none !important;
  border: none !important;
}

.userWelcome {
  font-size: 14px;
  font-weight: 500;
  color: #20776e;
}

textarea:focus {
  /* border: 1px solid #f1f0f0 !important; */
  /* background-color: transparent !important; */
}

textarea:active {
  border: none !important;
}

.successMessage {
  background-color: #f47896;
  /* Green background color */
  color: #fff;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.successMessage h1 {
  font-size: 20px;
  margin-bottom: 10px;
}

.successMessage p {
  font-size: 14px;
}

textarea::-webkit-scrollbar {
  width: 4px;
}

template::-webkit-scrollbar-track {
  background: rgb(148, 146, 146);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

textarea::-webkit-scrollbar-thumb {
  background: rgb(110, 110, 110);
  border-radius: 3px;
}

/* .input-set .inputContact input:focus {
  border: 1px solid #f1f0f0 !important;
  background-color: transparent !important;
} */
.inputContact {
  width: 100%;
}

.content {
  margin-top: 90px;
}

@media screen and (max-width: 992px) {
  h1,h2,h3,h4,h5,h6{
    margin-bottom: 0 !important;
  }
  .gap3{
    margin-bottom: 15px;
  }
  .for-text {
    width: 100% !important;
    max-width: 100% !important;
  }

  .for-text-2 {
    width: 100% !important;
    max-width: 100% !important;
  }

  .margin_mobile {
    margin-top: 50px !important;
  }

  .bg-stay-updated::before {
    display: none;
  }

  .happy_student img {
    max-height: 250px;
    object-fit: contain;
  }

  .dynamic-course::before {
    display: none;
  }

  .whychoose-section p {
    margin-left: 0% !important;
    font-size: 14px !important;
  }

  .world-section-text h3 {
    margin: 50px 0 !important;
  }

  .mobile {
    height: 60px;
    background-color: white;
    top: 0;
    position: sticky;
    z-index: 1;
    bottom: 0;
  }

  .mobile-navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    height: 60px;
  }

  .homeTop {
    margin-top: 10px !important;
    min-height: 300px !important;
  }

  .Topheader {
    display: none !important;
  }

  .header2 {
    display: none !important;
  }

  .bi-list {
    font-size: 40px !important;
  }

  .mobile-login {
    font-size: 18px !important;
  }

  .headerImg {
    max-width: 240px !important;
    margin-top: 8px !important;
  }

  .for-text-2::before {
    display: none !important;
  }

  .for-text::before {
    display: none !important;
  }

  .course-Showing {
    font-size: 95px !important;
  }

  .dynamic-course::before {
    top: 99px !important;
    right: 100px !important;
  }

  .couserTypeLogo {
    top: 126px !important;
    font-size: 14px !important;
  }

  .course-details h3 {
    font-size: 16px !important;
  }

  .course-details p {
    font-size: 14px !important;
  }

  .newDesignBtn {
    font-size: 16px !important;
  }

  .HomeBigtext {
    font-size: 37px !important;
    line-height: 30px !important;
  }

  .for-text p {
    font-size: 14px !important;
    line-height: 25px !important;
  }

  .for-text-2 p {
    font-size: 14px !important;
  }

  .HomeBigtext span {
    font-size: 37px !important;
    line-height: 0px !important;
  }

  .HomeBig-section {
    height: none !important;
  }

  .navContent ul li {
    font-size: 20px !important;
  }

  .bi-x-lg {
    font-size: 30px !important;
  }

  .homeSecond .imagesSet {
    margin-top: 263px !important;
  }

  .homeSecond::after {
    display: none !important;
  }

  .homeSecond::before {
    display: none !important;
  }

  .homeTop::after {
    display: none !important;
  }

  .homeTop::before {
    display: none !important;
  }

  .homeContent h1 {
    font-size: 44px !important;
    line-height: 53px !important;
  }

  .inputBar {
    width: 100% !important;
    padding-right: 89px !important;
    font-size: 14px !important;
    min-width: 100% !important;
  }

  .inputButton {
    /* padding: 8px 15px !important; */
    /* font-size: 17px !important; */
  }

  .homeTop {
    height: 300px !important;
  }

  .imagesSet .images {
    max-width: 150px !important;
  }

  .section-two {
    top: -46px !important;
  }

  .homeSecond {
    background: none !important;
    /* height: 100px !important; */
  }

  .whychoose-set1 h1 {
    font-size: 33px !important;
    line-height: 43px !important;
  }

  .whyChoose::before {
    display: none !important;
  }

  .forMobile {
    display: none !important;
  }

  .whychoose-set1 {
    gap: 1px !important;
  }

  .whychoose-set2 {
    gap: 1px !important;
  }

  .whychoose-section-2 {
    gap: 20px !important;
  }

  .explore-section h3 {
    font-size: 34px !important;
    line-height: 39px !important;
  }

  .homeSecond .imagesSet {
    gap: 20px !important;
  }

  .homeContent p {
    font-size: 14px !important;
    line-height: 27px;
  }

  .whychoose-section {
    font-size: 19px !important;
    line-height: 28px !important;
  }

  .course-type h3 {
    font-size: 18px !important;
  }

  .course-type p {
    font-size: 14px !important;
  }

  .explore-section p {
    font-size: 14px !important;
  }

  .HomeBig-section {
    height: 120px !important;
  }

  .happy-student-text h3 {
    font-size: 30px !important;
    line-height: 30px !important;
    margin-top: 18px !important;
  }

  .happy-student-text p {
    font-size: 16px !important;
  }

  .busines-school-image-set .text {
    /* top: -81px !important; */
    right: 0px !important;
    font-size: 22px !important;
    line-height: 25px !important;
  }

  .stay-updated-text h1 {
    font-size: 33px !important;
  }

  .stay-updated-text p {
    font-size: 16px !important;
  }

  .bg-stay-updated::before {
    width: 213px !important;
  }

  .stayupdated-input {
    font-size: 14px !important;
  }

  .acc-section h3 {
    font-size: 35px !important;
  }

  .acc-section p {
    font-size: 16px !important;
    line-height: 27px !important;
  }

  .acc-styles {
    font-size: 20px !important;
  }

  .acc-para {
    font-size: 18px !important;
    line-height: 23px !important;
  }

  .stay-update-section {
    padding: 0px !important;
  }

  .course-selection-section::before {
    display: none !important;
  }

  .best-feild::before {
    display: none !important;
  }

  .about-us-text h1 {
    font-size: 44px !important;
    line-height: 49px !important;
  }

  .about-us-text p {
    font-size: 19px !important;
    line-height: 26px !important;
  }

  .aboutFirst {
    max-width: 250px !important;
  }

  .best-field-heading h1 {
    font-size: 37px !important;
    line-height: 38px !important;
  }

  .best-field-para p {
    font-size: 18px !important;
  }

  .about-courses ul li {
    border: none !important;
    font-size: 22px !important;
  }

  .course-selection-text h3 {
    font-size: 37px !important;
    line-height: 36px !important;
  }

  .ready-start-section h1 {
    font-size: 37px !important;
    line-height: 44px !important;
  }

  .course-selection-text p {
    font-size: 19px !important;
  }

  .ready-start-section p {
    font-size: 19px !important;
    line-height: 20px !important;
  }

  .topStart::after {
    display: none !important;
  }

  .topStartCr::before {
    display: none !important;
  }

  .bottomStartCr::after {
    display: none !important;
  }

  .bottomStart::before {
    display: none !important;
  }

  .about-us-section {
    margin-top: 20px !important;
  }

  .school-top-section {
    gap: 91px !important;
    margin-top: 17px !important;
  }

  .school-top-section h1 {
    font-size: 37px !important;
    line-height: 47px !important;
  }

  .school-top-section p {
    font-size: 16px !important;
  }

  .world-section-text h3 {
    border: none !important;
    font-size: 37px !important;
    line-height: 44px !important;
  }

  .world-small-text h3 {
    font-size: 18px !important;
  }

  .world-small-text p {
    font-size: 16px !important;
  }

  .world-section-bg {
    padding: 70px !important;
    align-items: flex-start !important;
  }

  /* .settingHeightBusiness {
    height: 575px !important;
  } */
  .VideoBtn {
    width: 50px !important;
    height: 50px !important;
    font-size: 20px !important;
  }

  .world-small-text {
    flex-wrap: wrap !important;
  }

  .imageCollage {
    flex-wrap: unset !important;
  }

  .blank-bg {
    margin-top: -81px !important;
  }

  .section-header h2 {
    font-size: 35px !important;
  }

  .member-info h4 {
    font-size: 20px !important;
  }

  .item p {
    font-size: 14px !important;
  }

  .mt-5 {
    margin-bottom: 25px !important;
  }

  .mobile-offcanvas {
    width: 30% !important;
  }

  .userWelcome {
    font-size: 20px !important;
  }

  .mt-3 {
    font-size: 14px !important;
  }

  .modal-title {
    font-size: 18px !important;
  }

  .homeIcons::after {
    display: none !important;
  }

  .homeIcons::before {
    display: none !important;
  }

  .world-section-text .notShow {
    display: none !important;
  }

  .world-section-text .showWork {
    display: inline-block !important;
  }

  .callAction h1 {
    font-size: 26px !important;
  }

  .callAction p {
    font-size: 17px !important;
  }

  .benefitsCard {
    padding: 16px !important;
    gap: 7px !important;
  }

  .benefitsCard h1 {
    font-size: 18px !important;
    line-height: 22px !important;
  }
}

@media screen and (max-width: 768px) {
  .courses-header{
    margin-bottom: 30px !important;
  }
  h1,h2,h3,h4,h5,h6{
    margin-bottom: 0 !important;
  }
  .gap3{
    margin-bottom: 15px;
    gap: 15px;
  }
  
  .guide-img img.img-fluid {
    max-width: 80px;
    height: 80px !important;
    object-fit: cover;
  }

  .courses-header {
    margin-bottom: 20px;
    position: relative;
    margin-top: 20px;
  }

  section {
    padding: 0px !important;
  }

  .question_title {
    font-size: 14px !important;
    font-weight: 600 !important;
    line-height: normal !important;
  }

  .question-wrapp1 .form-group label {
    font-size: 14px !important;
  }

  .modal_n .quiz-wrapper h4 {
    font-size: 14px !important;
    line-height: normal !important;
  }

  .modal_n .modal_body {
    padding: 15px 15px !important;
  }

  .modal-footer_n {
    padding: 0px 0px 30px;
    flex-wrap: wrap;
  }

  .social-links a {
    margin-right: 0 !important;
  }

  .social-links {
    display: flex;
    justify-content: center;
    gap: 15px;
  }

  .footer .footer-content .footer-info p {
    text-align: center;
  }

  .footer .footer-content h4 {
    text-align: center;
    margin-bottom: 0 !important;
  }

  .footer .footer-content .footer-links ul li:first-child {
    padding-top: 10px !important;
  }

  .footer .footer-content .footer-links ul li {
    white-space: nowrap;
  }

  .footer .footer-content .footer-links ul {
    display: flex;
    gap: 15px;
    justify-content: center;
    flex-wrap: wrap;
  }

  .table .tr .td {
    padding: 8px 0 !important;
  }

  .tabs-wrapper li.nav-item {
    width: 100%;
  }

  .tabs-wrapper li.nav-item .nav-link.active:after {
    display: none;
  }

  .course-rightbar a {
    max-height: 35px;
    padding: 0;
    line-height: 34px !important;
    font-size: 14px;
  }

  .feedback-container {
    background-color: #fff;
    padding: 15px !important;
    border-radius: 30px;
    border: 1px solid #f0f0f0;
    text-align: center;
  }

  .rating-user ul {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    padding: 0;
  }

  .rating-user input:checked+label:before {
    font-size: 14px !important;
  }

  .rating-user label:before {
    top: -2px !important;
  }

  .rating-user ul li span {
    font-size: 14px !important;
  }

  .search-wrap {
    padding-bottom: 60px !important;
    width: 100%;
    margin-top: 55px;
  }

  .upl-bg .img-upload {
    border: 2px dashed #20776e;
    margin: 0 15px;
    border-radius: 5px;
  }

  .description {
    margin-top: 50px !important;
  }

  .pub-text button {
    background-color: rgb(252 196 23 / 45%);
    border: none;
    color: #888888;
    border-radius: 10px;
    padding: 2px 15px;
    font-size: 14px;
  }

  .offcanvas {
    width: 40% !important;
  }

  .center_item_mobile .callAction p {
    text-align: center;
  }

  .center_item_mobile .callAction a button {
    margin: auto;
  }

  .search_desktop {
    display: none;
  }

  .for-text-2,
  .for-text {
    width: 100%;
  }

  .for-text p,
  .for-text-2 p {
    font-size: 14px;
    text-align: center;
  }

  .for-text h3,
  .for-text-2 h3 {
    text-align: center;
    width: 100%;
  }

  .course-details p {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .whychoose-section p {
    margin-left: 0% !important;
  }

  .whychoose-set1 h1 {
    text-align: center;
    width: 100%;
  }

  .whychoose-section p {
    margin-left: 0 !important;
  }

  .mobile {
    height: 60px;
    background-color: white;
    top: 0;
    position: sticky;
    z-index: 1;
    bottom: 0;
  }

  .mobile-navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    height: 60px;
  }

  .homeTop {
    margin-top: 10px !important;
  }

  .Topheader {
    display: none !important;
  }

  .header2 {
    display: none !important;
  }

  .bi-list {
    font-size: 40px !important;
  }

  .mobile-login {
    font-size: 16px !important;
    padding: 5px 30px !important;
    border: 1px solid #20776e;
    height: 35px !important;
    line-height: normal;
  }

  .headerImg {
    max-width: 240px !important;
    margin-top: 0px !important;
  }

  .for-text-2::before {
    display: none !important;
  }

  .for-text::before {
    display: none !important;
  }

  .course-Showing {
    font-size: 95px !important;
  }

  .dynamic-course::before {
    top: 99px !important;
    right: 100px !important;
  }

  .couserTypeLogo {
    top: 124px !important;
    font-size: 13px !important;
  }

  .course-details h3 {
    font-size: 16px !important;
  }

  .course-details p {
    font-size: 14px !important;
  }

  .newDesignBtn {
    font-size: 15px !important;
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }

  .HomeBigtext {
    font-size: 32px !important;
    line-height: 40px !important;
  }

  .for-text p {
    font-size: 14px !important;
    line-height: 22px !important;
  }

  .for-text-2 p {
    font-size: 14px !important;
  }

  .HomeBigtext span {
    font-size: 37px !important;
    line-height: 0px !important;
  }

  .HomeBig-section {
    height: none !important;
  }

  p {
    font-size: 14px !important;
    line-height: 24px !important;
  }

  .navContent ul li {
    font-size: 16px !important;
  }

  .bi-x-lg {
    font-size: 25px !important;
  }

  .homeSecond .imagesSet {
    margin-top: 350px !important;
  }

  .homeSecond::after {
    display: none !important;
  }

  .homeSecond::before {
    display: none !important;
  }

  .homeTop::after {
    display: none !important;
  }

  .homeTop::before {
    display: none !important;
  }

  .homeContent h1 {
    font-size: 32px !important;
    line-height: 40px !important;
  }

  .inputBar {
    /* width: 455px !important; */
    padding-right: 89px !important;
    font-size: 14px !important;
    min-height: 45px !important;
  }

  .dynamic-course-section {
    justify-content: center;
    text-align: center;
  }

  .busines-school-section {
    justify-content: center;
  }

  .for-text {
    justify-content: center;
  }

  .inputButton {
    /* padding: 8px 15px !important; */
    /* font-size: 17px !important; */
    padding-left: 20px !important;
    padding-right: 20px !important;
    padding: 8px !important;
    width: 37px;
    height: 37px;
  }

  .inputButton .search_mobile {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .homeTop {
    height: 200px !important;
    min-height: 200px !important;
  }

  .imagesSet .images {
    max-width: 150px !important;
  }

  .section-two {
    top: -155px !important;
  }

  .homeSecond {
    background: none !important;
    /* height: 100px !important; */
    height: 100% !important;
  }

  .whychoose-set1 h1 {
    font-size: 35px !important;
    line-height: 40px !important;
  }

  .whyChoose::before {
    display: none !important;
  }

  .forMobile {
    display: none !important;
  }

  .whychoose-set1 {
    gap: 1px !important;
  }

  .whychoose-set2 {
    gap: 1px !important;
  }

  .whychoose-section-2 {
    gap: 20px !important;
    margin-top: 20px;
  }

  .explore-section h3 {
    font-size: 35px !important;
    line-height: 39px !important;
  }

  .homeSecond .imagesSet {
    gap: 20px !important;
  }

  .homeContent p {
    font-size: 15px !important;
    line-height: 23px !important;
  }

  .whychoose-section {
    font-size: 18px !important;
    line-height: 26px !important;
  }

  .course-type h3 {
    font-size: 20px !important;
  }

  .course-type p {
    font-size: 14px !important;
  }

  .explore-section p {
    font-size: 14px !important;
  }

  .HomeBig-section {
    height: 120px !important;
  }

  .happy-student-text h3 {
    font-size: 30px !important;
    line-height: 40px !important;
    margin-top: 18px !important;
  }

  .happy-student-text p {
    font-size: 16px !important;
  }

  .busines-school-image-set .text {
    /* top: -110px !important; */
    right: 0px !important;
    font-size: 26px !important;
    line-height: 32px !important;
  }

  .stay-updated-text h1 {
    font-size: 33px !important;
  }

  .stay-updated-text p {
    font-size: 16px !important;
  }

  .bg-stay-updated::before {
    display: none !important;
  }

  .stayupdated-input {
    font-size: 14px !important;
  }

  .acc-section h3 {
    font-size: 35px !important;
  }

  .acc-section p {
    font-size: 16px !important;
    line-height: 24px !important;
  }

  .acc-styles {
    font-size: 18px !important;
  }

  .acc-para {
    font-size: 16px !important;
    line-height: 23px !important;
  }

  .stay-update-section {
    padding: 0px !important;
  }

  .course-selection-section::before {
    display: none !important;
  }

  .best-feild::before {
    display: none !important;
  }

  .about-us-text h1 {
    font-size: 35px !important;
    line-height: 39px !important;
  }

  .about-us-text p {
    font-size: 15px !important;
    line-height: 20px !important;
  }

  .aboutFirst {
    max-width: 250px !important;
  }

  .best-field-heading h1 {
    font-size: 35px !important;
    line-height: 38px !important;
  }

  .best-field-para p {
    font-size: 15px !important;
  }

  .about-courses ul li {
    border: none !important;
    font-size: 18px !important;
  }

  .course-selection-text h3 {
    font-size: 35px !important;
    line-height: 36px !important;
  }

  .ready-start-section h1 {
    font-size: 32px !important;
    line-height: 44px !important;
  }

  .course-selection-text p {
    font-size: 15px !important;
  }

  .ready-start-section p {
    font-size: 15px !important;
    line-height: 20px !important;
  }

  .topStart::after {
    display: none !important;
  }

  .topStartCr::before {
    display: none !important;
  }

  .bottomStartCr::after {
    display: none !important;
  }

  .bottomStart::before {
    display: none !important;
  }

  .about-us-section {
    margin-top: 20px !important;
  }

  .school-top-section {
    gap: 15px !important;
    margin-top: 17px !important;
  }

  .school-top-section h1 {
    font-size: 30px !important;
    line-height: 37px !important;
  }

  .school-top-section p {
    font-size: 15px !important;
  }

  .world-section-text h3 {
    border: none !important;
    font-size: 35px !important;
    line-height: 44px !important;
    margin-bottom: 0 !important;
  }

  .world-small-text h3 {
    font-size: 18px !important;
  }

  .world-small-text p {
    font-size: 15px !important;
  }

  .world-section-bg {
    padding: 44px !important;
    align-items: flex-start !important;
  }

  /* .settingHeightBusiness {
    height: 444px !important;
  } */
  .VideoBtn {
    width: 50px !important;
    height: 50px !important;
    font-size: 20px !important;
  }

  .world-small-text {
    flex-wrap: wrap !important;
    gap: 0px !important;
  }

  .imageCollage {
    flex-wrap: unset !important;
  }

  .inMobile {
    display: none !important;
  }

  .contact-text-boxs h1 {
    font-size: 25px !important;
    margin-top: 0px !important;
    line-height: 30px !important;
  }

  .contact-text-boxs span {
    font-size: 25px !important;
    line-height: 30px !important;
  }

  .contact-section-bg::before {
    width: 100% !important;
  }

  .input-set .inputContact textarea {
    width: 209px !important;
  }

  .contact-text-boxs {
    gap: 5px !important;
  }

  .input-set .inputContact input {
    width: 100% !important;
  }

  .input-set .inputContact textarea {
    width: 338px !important;
  }

  .blank-bg {
    margin-top: -81px !important;
  }

  .section-header h2 {
    font-size: 35px !important;
  }

  .member-info h4 {
    font-size: 20px !important;
  }

  .item p {
    font-size: 14px !important;
  }

  .mt-5 {
    margin-bottom: 25px !important;
  }

  .mobile-offcanvas {
    width: 30% !important;
  }

  .userWelcome {
    font-size: 16px !important;
  }

  .mt-3 {
    font-size: 14px !important;
  }

  .modal-title {
    font-size: 18px !important;
  }

  .world-section-text .notShow {
    display: none !important;
  }

  .world-section-text .showWork {
    display: inline-block !important;
  }

  .callAction h1 {
    font-size: 26px !important;
  }

  .callAction p {
    font-size: 13px !important;
  }

  .benefitsCard {
    padding: 22px !important;
    gap: 7px !important;
  }

  .benefitsCard h1 {
    font-size: 18px !important;
    line-height: 22px !important;
  }
}
@media screen and (max-width:375px){
  .newDesignBtn{
    padding: 5px 10px;
  }
  label {
    display: inline-block;
    font-size: 14px !important;
}
h3{
  font-size: 18px !important;
}
select.form-select{
  font-size: 14px !important;
}
h5{
  font-size: 16px !important;
  margin-bottom: 0 !important;
}
.modal-body video{
  width: 100% !important;
}
.s-t,.e-t{
  width: 45%;
}
}
@media screen and (max-width: 425px) {
  .gap3{
    gap: 15px;
    margin-bottom: 15px;
  }
  .c-listing .title{
    margin-bottom: 10px;
    font-size: 18px; margin-top: 25px;
  }
  .l-head h1{
    margin-bottom: 0px;
    font-size:22px !important;
  }
  .allreviewsection {
    margin-top: 13px !important;
  }

  .play {
    top: 26% !important;
  }

  .play i {
    left: 41% !important;
    font-size: 36px !important;
    height: 54px !important;
    width: 55px !important;
    line-height: 56px !important;
  }

  .mobileViewRating {
    display: flex;
    gap: 50px;
  }

  button.accordion-button h5 {
    font-weight: 500 !important;
    font-size: 16px !important;
  }

  .rating {
    white-space: no-wrap;
    display: flex;
  }

  .td .courseName {
    font-size: 13px !important;
  }

  .teacherPagination {
    margin-left: -36px !important;
  }

  .studentPagination {
    margin-left: 35px !important;
  }

  .mypagenation li {
    padding: 5px 12px !important;
  }

  .ratingMessage {
    font-size: 12px !important;
  }

  .testimonials-section {
    margin-top: 0px !important;
    gap: 20px !important;
  }

  .testimonial-card {
    height: auto !important;
  }

  .modules-done {
    flex-wrap: wrap;
  }

  .mobile-offcanvas {
    width: 50% !important;
  }

  i.bi.bi-star-fill {
    font-size: 12px;
  }

  .animated {
    animation: none;
  }

  .about-courses ul li {
    padding: 0;
  }

  .about-courses ul {
    padding: 0;
    gap: 25px;
    justify-content: center;
  }

  .about-courses-bg {
    padding: 15px;
  }

  .for-text h3,
  .for-text-2 h3 {
    font-size: 28px;
    line-height: normal;
  }

  .happy-student-section {
    padding: 15px;
  }

  .input-wrapper {
    margin-bottom: 45px;
    padding: 0px;
  }

  .mobile {
    height: 60px;
    background-color: white;
    top: 0;
    position: sticky;
    z-index: 1;
    bottom: 0;
  }

  .mobile-navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    height: 60px;
  }

  .homeTop {
    margin-top: 10px !important;
  }

  .Topheader {
    display: none !important;
  }

  .header2 {
    display: none !important;
  }

  .bi-list {
    font-size: 30px !important;
  }

  .mobile-login {
    font-size: 15px !important;
  }

  .headerImg {
    max-width: 200px !important;
    margin-top: 0px !important;
  }

  .for-text-2::before {
    display: none !important;
  }

  .for-text::before {
    display: none !important;
  }

  .course-Showing {
    font-size: 60px !important;
  }

  .dynamic-course::before {
    display: none !important;
  }

  .couserTypeLogo {
    top: 119px !important;
    font-size: 14px !important;
  }

  .course-details h3 {
    font-size: 16px !important;
  }

  .course-details p {
    font-size: 15px !important;
  }

  .newDesignBtn {
    font-size: 14px !important;
    margin: auto;
    min-width: 120px !important;
  }

  .HomeBigtext {
    font-size: 20px !important;
    line-height: 30px !important;
  }

  .for-text p {
    font-size: 14px !important;
    line-height: 22px !important;
  }

  .for-text-2 p {
    font-size: 14px !important;
  }

  .HomeBigtext span {
    font-size: 20px !important;
    line-height: 0px !important;
  }

  .HomeBig-section {
    height: none !important;
  }

  .navContent ul li {
    font-size: 14px !important;
  }

  .bi-x-lg {
    font-size: 20px !important;
  }

  .homeSecond .imagesSet {
    margin-top: 263px !important;
  }

  .homeSecond::after {
    display: none !important;
  }

  .homeSecond::before {
    display: none !important;
  }

  .homeTop::after {
    display: none !important;
  }

  .homeTop::before {
    display: none !important;
  }

  .homeContent h1 {
    font-size: 20px !important;
    line-height: 25px !important;
  }

  .inputBar {
    font-size: 13px !important;
    padding-right: 50px !important;
    text-overflow: ellipsis;
  }

  .inputButton {
    font-size: 13px !important;
  }

  .homeTop {
    height: 100% !important;
  }

  .imagesSet .images {
    max-width: 120px !important;
  }

  .section-two {
    top: -155px !important;
  }

  .homeSecond {
    background: none !important;
    /* height: 100px !important; */
  }

  .whychoose-set1 h1 {
    font-size: 20px !important;
    line-height: 40px !important;
  }

  .whyChoose::before {
    display: none !important;
  }

  .forMobile {
    display: none !important;
  }

  .whychoose-set1 {
    gap: 1px !important;
    align-items: center !important;
  }

  .whychoose-set2 {
    gap: 25px !important;
  }

  .dynamic-course-section {
    top: 20px !important;
  }

  .whychoose-section-2 {
    gap: 20px !important;
  }

  .explore-section h3 {
    font-size: 24px !important;
    line-height: 27px !important;
  }

  .homeSecond .imagesSet {
    gap: 20px !important;
  }

  .homeContent p {
    font-size: 14px !important;
    line-height: 21px !important;
  }

  .whychoose-set2 .whychoose-section {
    gap: 0px !important;
  }

  .whychoose-section p {
    margin-left: 2% !important;
    margin-right: 2% !important;
  }

  .whychoose-section {
    font-size: 14px !important;
    line-height: 26px !important;
  }

  .course-type h3 {
    font-size: 17px !important;
  }

  .course-type p {
    font-size: 14px !important;
    margin-left: 0% !important;
    margin-right: 0% !important;
  }

  .explore-section p {
    font-size: 14px !important;
  }

  .HomeBig-section {
    height: 120px !important;
  }

  .happy-student-text h3 {
    font-size: 20px !important;
    line-height: 30px !important;
    margin-top: 18px !important;
  }

  .happy-student-text p {
    font-size: 14px !important;
  }

  .reviewCard {
    width: 313px !important;
  }

  .busines-school-image-set .text {
    /* top: -80px !important; */
    right: 0px !important;
    font-size: 18px !important;
    line-height: 25px !important;
  }

  .stay-updated-text h1 {
    font-size: 33px !important;
  }

  .stay-updated-text p {
    font-size: 14px !important;
  }

  .bg-stay-updated::before {
    display: none !important;
  }

  .stayupdated-input {
    font-size: 14px !important;
  }

  .acc-section h3 {
    font-size: 28px !important;
  }

  .acc-section p {
    font-size: 14px !important;
    line-height: 24px !important;
  }

  .acc-styles {
    font-size: 16px !important;
    padding: 15px !important;
  }

  .acc-para {
    font-size: 14px !important;
    line-height: 23px !important;
    padding: 15px !important;
  }

  .stay-update-section {
    padding: 0px !important;
  }

  .course-selection-section::before {
    display: none !important;
  }

  .best-feild::before {
    display: none !important;
  }

  .about-us-text h1 {
    font-size: 20px !important;
    line-height: 30px !important;
  }

  .about-us-text p {
    font-size: 14px !important;
    line-height: 20px !important;
  }

  .aboutFirst {
    max-width: 250px !important;
  }

  .best-field-heading h1 {
    font-size: 22px !important;
    line-height: 30px !important;
    justify-content: center !important;
    text-align: center !important;
  }

  .aboutBtn {
    padding-left: 0px !important;
    margin-top: 15px !important;
  }

  .best-field-para p {
    font-size: 14px !important;
    text-align: center !important;
  }

  .about-courses ul li {
    border: none !important;
    font-size: 16px !important;
  }

  .course-selection-text {
    justify-content: center !important;
  }

  .course-selection-text h3 {
    font-size: 22px !important;
    line-height: 26px !important;
    text-align: center !important;
    max-width: 100%;
  }

  .ready-start-section h1 {
    font-size: 20px !important;
    line-height: 26px !important;
  }

  .course-selection-text p {
    font-size: 14px !important;
    text-align: center !important;
  }

  .ready-start-section p {
    font-size: 13px !important;
    line-height: 20px !important;
  }

  .topStart::after {
    display: none !important;
  }

  .topStartCr::before {
    display: none !important;
  }

  .bottomStartCr::after {
    display: none !important;
  }

  .bottomStart::before {
    display: none !important;
  }

  .about-us-section {
    margin-top: 20px !important;
  }

  .school-top-section {
    gap: 15px !important;
    margin-top: 17px !important;
  }

  .school-top-section h1 {
    font-size: 20px !important;
    line-height: 27px !important;
  }

  .school-top-section p {
    font-size: 14px !important;
  }

  /* .world-section-text h3 {
    border: none !important;
    font-size: 23px !important;
    line-height: 34px !important;
  } */
  .world-small-text h3 {
    font-size: 18px !important;
  }

  .world-small-text p {
    font-size: 14px !important;
  }

  .world-section-bg {
    padding: 0px !important;
    align-items: flex-start !important;
  }

  .settingHeightSchool {
    height: 100% !important;
  }

  /* .settingHeightBusiness {
    height: 535px !important;
  } */
  .VideoBtn {
    width: 50px !important;
    height: 50px !important;
    font-size: 20px !important;
  }

  .world-small-text {
    flex-wrap: wrap !important;
    gap: 0px !important;
  }

  .imageCollage {
    flex-wrap: unset !important;
  }

  .inMobile {
    display: none !important;
  }

  .contact-text-boxs h1 {
    font-size: 25px !important;
    margin-top: 0px !important;
    line-height: 30px !important;
  }

  .contact-text-boxs span {
    font-size: 25px !important;
    line-height: 30px !important;
  }

  .contact-section-bg::before {
    width: 100% !important;
  }

  .input-set .inputContact textarea {
    width: 209px !important;
  }

  .contact-text-boxs {
    gap: 5px !important;
  }

  .input-set .inputContact input {
    width: 100% !important;
  }

  .input-set .inputContact textarea {
    width: 100% !important;
  }

  .blank-bg {
    margin-top: -81px !important;
  }

  .section-header h2 {
    font-size: 35px !important;
  }

  .member-info h4 {
    font-size: 20px !important;
  }

  .item p {
    font-size: 14px !important;
  }

  .mt-5 {
    margin-bottom: 25px !important;
  }

  /* .mobile-offcanvas {
    width: 43% !important;
  } */

  .userWelcome {
    font-size: 16px !important;
  }

  .mt-3 {
    font-size: 14px !important;
  }

  .modal-title {
    font-size: 18px !important;
  }

  .world-section-text .notShow {
    display: none !important;
  }

  .world-section-text .showWork {
    display: inline-block !important;
  }

  .callAction h1 {
    font-size: 26px !important;
  }

  .callAction p {
    font-size: 14px !important;
    text-align: center;
  }

  .benefitsCard {
    padding: 22px !important;
    gap: 7px !important;
  }

  .benefitsCard h1 {
    font-size: 18px !important;
    line-height: 22px !important;
  }

  .textCheck p {
    padding-right: 0% !important;
  }
}

.head_color {
  color: #000 !important;
}

.modal_n .title_heading {
  font-size: 26px;
  font-weight: 500;
}

.modal_n .quiz-wrapper h4 {
  font-size: 16px;
  color: #9ca3af;
  font-weight: 400;
  line-height: 30px;
}

.modal_n .modal_body {
  padding: 30px 50px;
}

.modal_n .quiz-wrapper h5 {
  font-size: 14px;
  color: #b0b0b0;
  font-weight: 500;
}

.question_title {
  color: #3f3f44;
  font-family: DM Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  /* 155.556% */
}

.modal_n .form-group {
  margin-bottom: 20px !important;
}

.btn-close {
  font-size: 14px;
}

.modal_n .modal-header {
  display: inline-flex;
}

@media screen and (min-width: 992px) {
  .header_2 {
    margin-top: 65px;
  }

  .for-text-2 {
    max-width: 50%;
  }

  .modal_n .modal-header {
    display: none !important;
  }

  .for-text h3,
  .for-text2 h3 {
    width: 180px !important;
  }
  .margin_desktop{
    margin-bottom: 30px;
  }

  
}

.dropdown-menu.show {
  top: 10px !important;
}

.image img {
  max-width: 80px;
  height: 100%;
  object-fit: cover;
  height: 80px;
}

.info {
  text-align: left;
}

.wrapper {
  flex-wrap: wrap;
}

.wrapper div .wrapper button {
  width: 100%;
}

.td .courseName {
  color: #3f3f44;
  font-family: 'DM Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  text-transform: capitalize;
}
.w30{
  width: 30px;
  height: 30px;
  object-fit: cover;
  min-width: 30px;
}

.notexitMesssage{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
  gap: 10px;
}

.notexitMesssage p{
  font-size: 18px !important;
  color: #1d1e3c;
  font-weight: 500 !important;
  max-width: 700px;
  padding: 10px;
  text-align: center;
}

.notexitMesssage img{
  max-width: 300px;
}
