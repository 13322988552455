@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");

:root {
  scroll-behavior: smooth;
}

body {
  font-family: "DM Sans", sans-serif !important;
  background-color: #f6f7fb !important;
}

a {
  font-family: "DM Sans", sans-serif !important;
  font-weight: 500;
}

.dropdown-menu.show {
  border: 1px solid #f0f0f0;
  box-shadow: 0px 0px 15px rgb(0 0 0 / 6%);
}

a:hover {
  color: var(--color-links-hover);
  text-decoration: none;
}

.rating-image {
  height: 55px !important;
  width: 55px;
  border-radius: 50%;
  object-fit: cover;
}

.collaps {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  /* Number of lines to display */
  line-height: 1.5;
  /* Adjust as needed */
  padding: 0 !important;
}

.para-span span {
  color: #00786e;
  font-size: 13px;
  text-decoration: underline;
  cursor: pointer;
}

/* .rating-section span {
  font-size: 12px;
  color: rgba(29, 30, 60, 0.5);
  text-transform: none;
  margin-left: 10px;
} */
.rating-section .details {
  display: flex;
  flex-direction: column;
}

.rating-section .title {
  color: #1d1e3c;
  font-family: "DM Sans";
  font-size: 14px;
  font-style: normal;
  line-height: 0px;
  font-weight: 700;
  text-transform: capitalize;
}

.rating-section p {
  color: #9ca3af;
  font-family: "DM Sans";
  font-size: 12px;
  font-style: italic;
  margin-top: 10px;
  font-weight: 400;
  line-height: 20px;
}

.my-progress-bar {
  height: 15px !important;
  width: 100%;
  border-radius: 10px !important;
}

.progress-bar {
  background-color: #fe981f !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #1d1e3c;
}

/*--------------------------------------------------------------
# Preloader
--------------------------------------------------------------*/
#preloader {
  position: fixed;
  inset: 0;
  z-index: 9999;
  overflow: hidden;
  background: var(--color-white);
  transition: all 0.6s ease-out;
  width: 100%;
  height: 100vh;
}

#preloader:before,
#preloader:after {
  content: "";
  position: absolute;
  border: 4px solid var(--color-primary);
  border-radius: 50%;
  -webkit-animation: animate-preloader 2s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  animation: animate-preloader 2s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

#preloader:after {
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s;
}

@-webkit-keyframes animate-preloader {
  0% {
    width: 10px;
    height: 10px;
    top: calc(50% - 5px);
    left: calc(50% - 5px);
    opacity: 1;
  }

  100% {
    width: 72px;
    height: 72px;
    top: calc(50% - 36px);
    left: calc(50% - 36px);
    opacity: 0;
  }
}

@keyframes animate-preloader {
  0% {
    width: 10px;
    height: 10px;
    top: calc(50% - 5px);
    left: calc(50% - 5px);
    opacity: 1;
  }

  100% {
    width: 72px;
    height: 72px;
    top: calc(50% - 36px);
    left: calc(50% - 36px);
    opacity: 0;
  }
}

/*--------------------------------------------------------------
# Sections & Section Header
--------------------------------------------------------------*/
section {
  padding: 80px 0;
  overflow: hidden;
}

.section-header {
  text-align: center;
  padding-bottom: 40px;
}

.section-header h2 {
  font-size: 48px;
  font-weight: 300;
  margin-bottom: 20px;
  color: var(--color-secondary);
}

.section-header p {
  margin: 0 auto;
  color: var(--color-secondary-light);
}

.modal-footer_n {
  padding: 0px 20px 30px;
  display: flex;
  justify-content: space-between;
}

.modal .quiz-wrapper {
  background-color: #ffffff;
  padding: 0;
  margin-top: 20px;
  border-radius: 15px;
}

.modal-header button.close {
  background-color: #ffc007;
  border: none;
  color: #fff;
  height: 30px !important;
  width: 30px !important;
  line-height: 18px !important;
  border-radius: 100px;
  font-size: 30px !important;
  position: absolute;
  top: -10px;
  right: -10px;
}

.modal-wrapper .next {
  background-color: #20776e;
  border: 1px solid #20776e;
  padding: 0;
  border-radius: 40px;
  width: 167px;
  height: 43px;
}

.modal-wrapper .prev {
  background-color: transparent;
  border: none;
  color: #20776e;
  font-weight: bold;
  padding: 0;
}

.modal-wrapper .modal-header {
  padding: 0;
  border: none;
}

/* closed input type radioo */

@media (min-width: 992px) {
  .search-wrap {
    width: 638px;
  }

  .feedback-container p {
    width: 480px;
  }

  .p-l {
    padding-left: 60px;
    padding-top: 120px;
  }


  .modal-wrapper .modal-dialog .modal-content {
    padding: 30px;

  }
}

@media (min-width: 1280px) {
  .section-header-c p {
    /* width: 420px; */
  }

  .right-img .input-group {
    width: 60%;
  }

  .headingwrap .section-header {
    text-align: center;
    width: 790px;
    margin: 0 auto;
  }

  .footer-info {
    width: 339px;
  }
}

/*--------------------------------------------------------------
# Breadcrumbs
--------------------------------------------------------------*/
.breadcrumbs {
  padding: 15px 0;
  background: rgba(var(--color-secondary-rgb), 0.05);
  min-height: 40px;
  margin-top: 76px;
}

.breadcrumbs h2 {
  font-size: 30px;
  font-weight: 300;
  margin: 0;
}

.breadcrumbs ol {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 14px;
}

.breadcrumbs ol li+li {
  padding-left: 10px;
}

.breadcrumbs ol li+li::before {
  display: inline-block;
  padding-right: 10px;
  color: var(--color-secondary-light);
  content: "/";
}

@media (max-width: 992px) {
  .breadcrumbs .d-flex {
    display: block !important;
  }

  .breadcrumbs h2 {
    margin-bottom: 10px;
    font-size: 24px;
  }

  .breadcrumbs ol {
    display: block;
  }

  .breadcrumbs ol li {
    display: inline-block;
  }
}

/*--------------------------------------------------------------
# Scroll top button
--------------------------------------------------------------*/
.scroll-top {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  right: 15px;
  bottom: 15px;
  z-index: 995;
  background: var(--color-primary);
  width: 40px;
  height: 40px;
  border-radius: 4px;
  transition: all 0.4s;
}

.scroll-top i {
  font-size: 24px;
  color: var(--color-white);
  line-height: 0;
}

.scroll-top:hover {
  background: rgba(var(--color-primary-rgb), 0.85);
  color: var(--color-white);
}

.scroll-top.active {
  visibility: visible;
  opacity: 1;
}

/*--------------------------------------------------------------
# Disable aos animation delay on mobile devices
--------------------------------------------------------------*/
@media screen and (max-width: 768px) {
  [data-aos-delay] {
    transition-delay: 0 !important;
  }
}

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
.header {
  padding: 15px 0;
  transition: all 0.5s;
  z-index: 999999;
  background: #fff;
}

.header.sticked {
  box-shadow: 0px 2px 20px rgba(var(--color-secondary-rgb), 0.1);
}

.header .logo img {
  max-height: 40px;
  margin-right: 6px;
}

.header .logo h1 {
  font-size: 32px;
  font-weight: 300;
  color: var(--color-secondary);
  font-family: var(--font-secondary);
}

.header .logo h1 span {
  color: var(--color-primary);
  font-weight: 500;
}

.header .btn-getstarted,
.header .btn-getstarted:focus {
  font-size: 16px;
  color: var(--color-white);
  background: var(--color-primary);
  padding: 8px 23px;
  border-radius: 4px;
  transition: 0.3s;
  font-family: var(--font-secondary);
}

.header .btn-getstarted:hover,
.header .btn-getstarted:focus:hover {
  color: var(--color-white);
  background: rgba(var(--color-primary-rgb), 0.85);
}

.search-wrap input[type="search"]:focus {
  background-color: white !important;
}

@media (max-width: 1279px) {

  .header .btn-getstarted,
  .header .btn-getstarted:focus {
    margin-right: 50px;
  }
}

/*--------------------------------------------------------------
# Desktop Navigation 
--------------------------------------------------------------*/
@media (min-width: 1280px) {
  .navbar {
    padding: 0;
    position: relative;
  }

  .navbar ul {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
    align-items: center;
  }

  .navbar li {
    position: relative;
  }

  .navbar>ul>li {
    white-space: nowrap;
  }

  .navbar a,
  .navbar a:focus {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 14px 15px;
    font-size: 16px;
    font-weight: 500;
    color: rgba(var(--color-secondary-dark-rgb), 0.7);
    white-space: nowrap;
    transition: 0.3s;
    position: relative;
    text-decoration: none;
  }

  .navbar a i,
  .navbar a:focus i {
    font-size: 12px;
    line-height: 0;
    margin-left: 5px;
  }

  .navbar>ul>li>a:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: var(--color-primary);
    visibility: hidden;
    transition: all 0.3s ease-in-out 0s;
    transform: scaleX(0);
    transition: all 0.3s ease-in-out 0s;
  }

  .navbar a:hover:before,
  .navbar li:hover>a:before,
  .navbar .active:before {
    visibility: visible;
    transform: scaleX(0.7);
  }

  .navbar a:hover,
  .navbar .active,
  .navbar .active:focus,
  .navbar li:hover>a {
    color: var(--color-primary);
  }

  .navbar .dropdown a:hover:before,
  .navbar .dropdown:hover>a:before,
  .navbar .dropdown .active:before {
    visibility: hidden;
  }

  .navbar .dropdown a:hover,
  .navbar .dropdown .active,
  .navbar .dropdown .active:focus,
  .navbar .dropdown:hover>a {
    color: var(--color-white);
    background: var(--color-secondary);
  }

  .navbar .dropdown ul {
    display: block;
    position: absolute;
    left: 0;
    top: 100%;
    margin: 0;
    padding: 0 0 0px 0;
    z-index: 99;
    opacity: 0;
    visibility: hidden;
    transition: 0.3s;
    color: #fff;
    background: #20776e;
  }

  .navbar .dropdown ul li {
    min-width: 200px;
  }

  .navbar .dropdown ul a {
    padding: 10px 20px;
    font-size: 15px;
    text-transform: none;
    font-weight: 400;
    color: rgba(var(--color-white-rgb), 0.5);
  }

  .navbar .dropdown ul a i {
    font-size: 12px;
  }

  .navbar .dropdown ul a:hover,
  .navbar .dropdown ul .active,
  .navbar .dropdown ul .active:hover,
  .navbar .dropdown ul li:hover>a {
    color: var(--color-white);
    background: var(--color-primary);
  }

  .navbar .dropdown:hover>ul {
    opacity: 1;
    visibility: visible;
  }

  .navbar .megamenu {
    position: static;
  }

  .navbar .megamenu ul {
    right: 0;
    padding: 10px;
    display: flex;
  }

  .navbar .megamenu ul li {
    flex: 1;
  }

  .navbar .megamenu ul li a,
  .navbar .megamenu ul li:hover>a {
    color: rgba(var(--color-white-rgb), 0.5);
    background: none;
  }

  .navbar .megamenu ul li a:hover,
  .navbar .megamenu ul li .active,
  .navbar .megamenu ul li .active:hover {
    color: var(--color-white);
    background: var(--color-primary);
  }

  .navbar .dropdown .dropdown ul {
    top: 0;
    left: calc(100% - 30px);
    visibility: hidden;
  }

  .navbar .dropdown .dropdown:hover>ul {
    opacity: 1;
    top: 0;
    left: 100%;
    visibility: visible;
  }
}

@media (min-width: 1280px) and (max-width: 1366px) {
  .navbar .dropdown .dropdown ul {
    left: -90%;
  }

  .navbar .dropdown .dropdown:hover>ul {
    left: -100%;
  }
}

/*--------------------------------------------------------------
# Mobile Navigation
--------------------------------------------------------------*/
@media (max-width: 1279px) {
  .navbar {
    position: fixed;
    top: 0;
    left: -100%;
    width: calc(100% - 70px);
    bottom: 0;
    transition: 0.3s;
    z-index: 9997;
  }

  .navbar ul {
    position: absolute;
    inset: 0;
    padding: 10px 0;
    margin: 0;
    background: rgba(var(--color-secondary-rgb), 0.9);
    overflow-y: auto;
    transition: 0.3s;
    z-index: 9998;
  }

  .navbar a,
  .navbar a:focus {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 20px;
    font-size: 16px;
    font-weight: 500;
    color: rgba(var(--color-white-rgb), 0.7);
    white-space: nowrap;
    transition: 0.3s;
  }

  .navbar a i,
  .navbar a:focus i {
    font-size: 12px;
    line-height: 0;
    margin-left: 5px;
  }

  .navbar a:hover,
  .navbar .active,
  .navbar .active:focus,
  .navbar li:hover>a {
    color: var(--color-white);
  }

  .navbar .dropdown ul,
  .navbar .dropdown .dropdown ul {
    position: static;
    display: none;
    padding: 10px 0;
    margin: 10px 20px;
    transition: all 0.5s ease-in-out;
    border: 1px solid rgba(var(--color-secondary-light-rgb), 0.3);
  }

  .navbar .dropdown>.dropdown-active,
  .navbar .dropdown .dropdown>.dropdown-active {
    display: block;
  }

  .mobile-nav-toggle {
    display: block !important;
    color: var(--color-secondary);
    font-size: 28px;
    cursor: pointer;
    line-height: 0;
    transition: 0.5s;
    position: fixed;
    top: 20px;
    z-index: 9999;
    right: 20px;
  }

  .mobile-nav-toggle.bi-x {
    color: var(--color-white);
  }

  .mobile-nav-active {
    overflow: hidden;
    z-index: 9995;
    position: relative;
  }

  .mobile-nav-active .navbar {
    left: 0;
  }

  .mobile-nav-active .navbar:before {
    content: "";
    position: fixed;
    inset: 0;
    background: rgba(var(--color-secondary-rgb), 0.8);
    z-index: 9996;
  }
}

/*--------------------------------------------------------------
# Index Page
--------------------------------------------------------------*/
/*--------------------------------------------------------------
# Animated Hero Section
--------------------------------------------------------------*/
.hero-animated {
  width: 100%;
  min-height: 50vh;
  background-size: cover;
  position: relative;
  padding: 120px 0 60px;
}

.hero-animated h2 {
  margin: 0 0 10px 0;
  font-size: 48px;
  font-weight: 300;
  color: var(--color-secondary);
  font-family: var(--font-secondary);
}

h3 {
  font-size: 50px;
  line-height: 60px;
  color: #1d1e3c;
}

.hero-animated h2 span {
  color: var(--color-primary);
}

.hero-animated p {
  color: rgba(var(--color-secondary-rgb), 0.8);
  margin: 0 0 30px 0;
  font-size: 20px;
  font-weight: 400;
}

.hero-animated .animated {
  margin-bottom: 60px;
  animation: up-down 2s ease-in-out infinite alternate-reverse both;
}

.learning p,
.instructor p {
  line-height: 40px;
  color: #9ca3af;
  width: 426px;
  font-size: 17px;
  padding-top: 30px;
}

.cta-btn:hover {
  background: #f2a227;
  color: #fff;
}

.cta-btn {
  width: 215px;
  height: 48px;
  font-size: 16px;
  color: #fff;
  margin-right: 30px;
  background-color: #00786e;
  text-align: center;
  line-height: 48px;
  text-decoration: none;
  border-radius: 100px;
}

@media (min-width: 992px) {
  .hero-animated .animated {
    max-width: 45%;
  }
}

@media (max-width: 991px) {
  .hero-animated .animated {
    max-width: 60%;
  }
}

@media (max-width: 575px) {
  .hero-animated .animated {
    max-width: 80%;
  }
}

.hero-animated .btn-get-started {
  font-size: 16px;
  font-weight: 400;
  display: inline-block;
  padding: 10px 28px;
  border-radius: 4px;
  transition: 0.5s;
  color: var(--color-white);
  background: var(--color-primary);
  font-family: var(--font-secondary);
}

.hero-animated .btn-get-started:hover {
  background: rgba(var(--color-primary-rgb), 0.8);
}

.hero-animated .btn-watch-video {
  font-size: 16px;
  transition: 0.5s;
  margin-left: 25px;
  font-family: var(--font-secondary);
  color: var(--color-secondary);
  font-weight: 600;
}

.hero-animated .btn-watch-video i {
  color: var(--color-primary);
  font-size: 32px;
  transition: 0.3s;
  line-height: 0;
  margin-right: 8px;
}

.hero-animated .btn-watch-video:hover {
  color: var(--color-primary);
}

.hero-animated .btn-watch-video:hover i {
  color: rgba(var(--color-primary-rgb), 0.8);
}

@media (max-width: 640px) {
  .hero-animated h2 {
    font-size: 32px;
  }

  .hero-animated p {
    font-size: 18px;
    margin-bottom: 30px;
  }

  .hero-animated .btn-get-started,
  .hero-animated .btn-watch-video {
    font-size: 14px;
  }
}

@-webkit-keyframes up-down {
  0% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(-10px);
  }
}

@keyframes up-down {
  0% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(-10px);
  }
}

/*--------------------------------------------------------------
# Carousel Hero Section
--------------------------------------------------------------*/
.hero {
  width: 100%;
  min-height: 60vh;
  padding: 0;
  background: var(--color-black);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 100px 0 60px 0;
}

@media (max-width: 640px) {
  .hero .container {
    padding: 0 15px;
  }
}

.hero h2 {
  color: var(--color-secondary);
  margin-bottom: 25px;
  font-size: 48px;
  font-weight: 300;
  -webkit-animation: fadeInDown 1s both 0.2s;
  animation: fadeInDown 1s both 0.2s;
}

@media (max-width: 768px) {
  .hero h2 {
    font-size: 30px;
  }
}

.hero p {
  color: var(--color-secondary-light);
  -webkit-animation: fadeInDown 1s both 0.4s;
  animation: fadeInDown 1s both 0.4s;
  font-weight: 500;
  margin-bottom: 30px;
}

.hero .img {
  margin-bottom: 40px;
  -webkit-animation: fadeInDownLite 1s both;
  animation: fadeInDownLite 1s both;
}

.hero .btn-get-started {
  font-family: var(--font-secondary);
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 8px 32px;
  border-radius: 5px;
  transition: 0.5s;
  -webkit-animation: fadeInUp 1s both 0.6s;
  animation: fadeInUp 1s both 0.6s;
  color: var(--color-primary);
  border: 2px solid var(--color-primary);
}

.hero .btn-get-started:hover {
  background: var(--color-primary);
  color: var(--color-white);
}

.hero .carousel-control-prev {
  justify-content: start;
}

@media (min-width: 640px) {
  .hero .carousel-control-prev {
    padding-left: 15px;
  }
}

.hero .carousel-control-next {
  justify-content: end;
}

@media (min-width: 640px) {
  .hero .carousel-control-next {
    padding-right: 15px;
  }
}

.hero .carousel-control-next-icon,
.hero .carousel-control-prev-icon {
  background: none;
  font-size: 26px;
  line-height: 0;
  background: rgba(var(--color-secondary-rgb), 0.4);
  color: rgba(var(--color-white-rgb), 0.98);
  border-radius: 50px;
  width: 54px;
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hero .carousel-control-next-icon {
  padding-left: 3px;
}

.hero .carousel-control-prev-icon {
  padding-right: 3px;
}

.hero .carousel-control-prev,
.hero .carousel-control-next {
  transition: 0.3s;
}

.hero .carousel-control-prev:focus,
.hero .carousel-control-next:focus {
  opacity: 0.5;
}

.hero .carousel-control-prev:hover,
.hero .carousel-control-next:hover {
  opacity: 0.9;
}

.hero .carousel-indicators li {
  cursor: pointer;
  background: rgba(var(--color-secondary-rgb), 0.5);
  overflow: hidden;
  border: 0;
  width: 12px;
  height: 12px;
  border-radius: 50px;
  opacity: 0.6;
  transition: 0.3s;
}

.hero .carousel-indicators li.active {
  opacity: 1;
  background: var(--color-primary);
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@-webkit-keyframes fadeInDownLite {
  from {
    opacity: 0;
    transform: translate3d(0, -10%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInDownLite {
  from {
    opacity: 0;
    transform: translate3d(0, -10%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

/*--------------------------------------------------------------
# Fullscreen Hero Section
--------------------------------------------------------------*/
.hero-fullscreen {
  width: 100%;
  min-height: 100vh;
  background-size: cover;
  position: relative;
  padding: 120px 0 60px;
}

.hero-fullscreen:before {
  content: "";
  background: rgba(var(--color-white-rgb), 0.85);
  position: absolute;
  inset: 0;
}

@media (min-width: 1365px) {
  .hero-fullscreen {
    background-attachment: fixed;
  }
}

.hero-fullscreen h2 {
  margin: 0 0 10px 0;
  font-size: 48px;
  font-weight: 300;
  color: var(--color-secondary);
  font-family: var(--font-secondary);
}

.hero-fullscreen h2 span {
  color: var(--color-primary);
}

.hero-fullscreen p {
  color: rgba(var(--color-secondary-rgb), 0.8);
  margin: 0 0 30px 0;
  font-size: 20px;
  font-weight: 400;
}

.hero-fullscreen .btn-get-started {
  font-size: 16px;
  font-weight: 400;
  display: inline-block;
  padding: 10px 28px;
  border-radius: 4px;
  transition: 0.5s;
  color: var(--color-white);
  background: var(--color-primary);
  font-family: var(--font-secondary);
}

.login_btns button:first-child {
  margin-right: 10px;
}

.hero-fullscreen .btn-get-started:hover {
  background: rgba(var(--color-primary-rgb), 0.8);
}

.hero-fullscreen .btn-watch-video {
  font-size: 16px;
  transition: 0.5s;
  margin-left: 25px;
  font-family: var(--font-secondary);
  color: var(--color-secondary);
  font-weight: 600;
}

.hero-fullscreen .btn-watch-video i {
  color: var(--color-primary);
  font-size: 32px;
  transition: 0.3s;
  line-height: 0;
  margin-right: 8px;
}

.hero-fullscreen .btn-watch-video:hover {
  color: var(--color-primary);
}

.hero-fullscreen .btn-watch-video:hover i {
  color: rgba(var(--color-primary-rgb), 0.8);
}

@media (max-width: 640px) {
  .hero-fullscreen h2 {
    font-size: 32px;
  }

  .hero-fullscreen p {
    font-size: 18px;
    margin-bottom: 30px;
  }

  .hero-fullscreen .btn-get-started,
  .hero-fullscreen .btn-watch-video {
    font-size: 14px;
  }
}

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
.footer {
  color: #d9d9d9;
  font-size: 14px;
}

.footer .footer-content {
  background-color: #20776e;
  padding: 60px 0 30px 0;
}

.footer .footer-content .footer-info {
  margin-bottom: 30px;
}

.footer .footer-content .footer-info h3 {
  font-size: 28px;
  margin: 0 0 20px 0;
  padding: 2px 0 2px 0;
  line-height: 1;
  font-weight: 700;
  text-transform: uppercase;
}

.footer-links li {
  margin-bottom: 10px;
}

.footer-links a {
  text-decoration: none;
  text-transform: uppercase;
  font-size: 14px;
  color: #d9d9d9;
}

.footer .footer-content .footer-info h3 span {
  color: var(--color-primary);
}

.footer-links h4 {
  text-transform: uppercase;
  color: #ffffff !important;
}

.social-links a i {
  color: #fff;
  margin-right: 10px;
}

.footer .footer-content .footer-info p {
  font-size: 16px;
  line-height: 27px;
  margin-bottom: 0;
  font-family: var(--font-primary);
  color: #d9d9d9;
  margin-top: 18px;
}

.footer .footer-content h4 {
  font-size: 16px;
  font-weight: 600;
  color: var(--color-white);
  position: relative;
  padding-bottom: 12px;
  margin-bottom: 15px;
}

.footer .footer-content h4::after {
  content: "";
  position: absolute;
  display: block;
  width: 20px;
  height: 2px;
  background: var(--color-primary);
  bottom: 0;
  left: 0;
}

.footer .footer-content .footer-links {
  margin-bottom: 30px;
}

.footer .footer-content .footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer .footer-content .footer-links ul i {
  padding-right: 2px;
  color: var(--color-white);
  font-size: 12px;
  line-height: 1;
}

.footer .footer-content .footer-links ul li {
  padding: 10px 0;
  display: flex;
  align-items: center;
}

.footer .footer-content .footer-links ul li:first-child {
  padding-top: 0;
}

.footer .footer-content .footer-links ul a {
  color: rgba(var(--color-white-rgb), 0.7);
  transition: 0.3s;
  display: inline-block;
  line-height: 1;
}

.footer .footer-content .footer-links ul a:hover {
  color: var(--color-white);
}

.footer .footer-content .footer-newsletter form {
  margin-top: 30px;
  background: var(--color-white);
  padding: 6px 10px;
  position: relative;
  border-radius: 4px;
}

.footer .footer-content .footer-newsletter form input[type="email"] {
  border: 0;
  padding: 4px;
  width: calc(100% - 110px);
}

.footer .footer-content .footer-newsletter form input[type="email"]:focus-visible {
  outline: none;
}

.footer .footer-content .footer-newsletter form input[type="submit"] {
  position: absolute;
  top: 0;
  right: -2px;
  bottom: 0;
  border: 0;
  background: none;
  font-size: 16px;
  padding: 0 20px;
  background: var(--color-primary);
  color: var(--color-white);
  transition: 0.3s;
  border-radius: 0 4px 4px 0;
}

.footer .footer-legal {
  padding: 30px 0;
  background: #20776e;
  border-top: 1px solid rgb(246 247 251 / 9%);
}

.social-links {
  margin-top: 30px;
}

.social-links a {
  margin-right: 20px;
  display: inline-block;
}

.footer .footer-legal .credits {
  padding-top: 4px;
  font-size: 13px;
  color: var(--color-white);
}

.footer .footer-legal .credits a {
  color: var(--color-primary-light);
}

.footer .footer-legal .social-links a {
  font-size: 18px;
  display: inline-block;
  background: rgba(var(--color-white-rgb), 0.1);
  color: var(--color-white);
  line-height: 1;
  padding: 8px 0;
  margin-right: 4px;
  border-radius: 4px;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
}

.footer .footer-legal .social-links a:hover {
  background: var(--color-primary);
  text-decoration: none;
}

.green_btn {
  background: #20776e;
  color: #fff;
  border-radius: 33px;
  transition: all 0.3s ease-in-out;
}

.green_btn:hover {
  background: #f2a227;
  color: #fff;
}

.hero {
  background: #f6f7fb;
}

.hero h1 {
  font-weight: 700;
  color: #1d1e3c;
  font-size: 35px;
}

.hero p {
  font-size: 20px;
  color: #9ca3af;
  font-weight: 400;
}

.login_btns .btn.green_btn {
  background-color: #00786e;
  color: #fff;
  border-radius: 100px;
  height: 44px;
  line-height: 44px;
  padding: 0 20px;
  transition: all ease 0.5s;
}

.footer-links ul a,
a.nav-link {
  position: relative;
}

.footer-links ul a:hover:after {
  width: 100%;
}

.footer-links ul a:after {
  content: "";
  position: absolute;
  left: 0;
  width: 0;
  background-color: #fff;
  height: 1px;
  bottom: -2px;
  transition: all ease 0.5s;
}

.watch_btn {
  transition: all ease 0.5s;
}

.nav-link:hover {
  color: #007a6e !important;
}

.hero_btns .green_btn {
  width: 154px;
  height: 48px;
  font-size: 16px;
  color: #fff;
  margin-right: 30px;
  background-color: #00786e;
  border-radius: 100px;
  transition: all ease 0.5s;
}

.hero_btns .green_btn:hover,
.login_btns .btn.green_btn:hover {
  background-color: #f2a227;
  color: #fff;
}

.watch_btn svg {
  box-shadow: 6px 6px 32px rgb(32, 119, 110, 0.21);
  border-radius: 100%;
  margin-right: 15px;
}

.watch_btn {
  font-size: 16px;
  font-weight: 500;
  transition: all 0.3s ease-in-out;
}

.title-bg input {
  background-color: #f0f0f0;
}

.upload-pic.upl-bg {
  margin-top: 35px;
}

.watch_btn:hover {
  transform: translateX(-10px);
}

.counter {
  margin-top: 80px;
  display: flex;
  align-items: center;
  gap: 70px;
}

.counter h3 {
  color: rgba(32, 119, 110, 1);
  font-size: 30px;
  line-height: 36px;
  font-weight: 700;
  margin: 0;
  text-align: center;
}

.counter p {
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
}

.realestate li {
  font-weight: bold;
  color: #20776e;
  text-transform: capitalize;
  font-size: 22px;
  position: relative;
}

.popular-item {
  border: 1px solid #d9d9d9;
  padding: 20px;
  border-radius: 20px;
}

.popular-item .user-rating {
  margin-top: 10px;
}

.popular-item .member-info span {
  color: #1d1e3c;
  font-size: 16px;
}

.realestate li:last-child:after {
  content: none;
}

.c-tabs .realestate li:after {
  right: -40px;
}

.popular-item .rating-count {
  padding-left: 0;
}

.popular-item p {
  color: #9ca3af;
  margin-top: 20px;
  font-weight: 300;
  line-height: 29px;
}

.popular-item .all-count {
  color: #9ca3af;
}

.realestate li:after {
  position: absolute;
  right: -55px;
  background-color: #ebebeb;
  height: 40px;
  width: 2px;
  content: "";
  margin-right: 22px;
}

.properties__img a img {
  width: 100%;
  border-radius: 12px;
  height: 226px;
  object-fit: cover;
}

.namecour img {
  height: 50px;
  width: 50px;
  object-fit: cover;
  border-radius: 100%;
}

.properties__caption p {
  font-size: 16px;
  color: #9ca3af;
}

.properties__img {
  position: relative;
}

.properties__caption p {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  /* Limit to three lines */
  -webkit-box-orient: vertical;
}

.rating-user .bi.bi-star-fill::before {
  color: #d9d9d9;
}

.bi.bi-star-fill::before {
  color: #ffc007;
  padding: 0px 2px;
}

.bi.bi-star::before {
  color: #ffc007;
  padding: 0px 2px;
}

.properties__card {
  padding: 20px;
  border: 1px solid #d9d9d9;
  border-radius: 12px;
}

.beginner {
  color: #ff8a00;
}

.advance {
  color: #53b3cb;
}

.expert {
  color: #f61681;
}

.b-btn {
  background-color: #fafafa;
  text-decoration: none;
  text-transform: uppercase;
  padding: 5px 10px;
  border-radius: 15px;
  border: 0px;
  font-size: 13px;
  font-weight: bold;
}

.all-count {
  padding-left: 10px;
}

.profile-wrap img {
  width: 300px;
  margin-bottom: 20px;
}

.namecour span {
  font-size: 18px;
  padding-left: 10px;
  color: #1d1e3c;
}

.properties__footer {
  padding: 30px 0 0;
}

.pic img {
  height: 60px;
  width: 60px;
  object-fit: cover;
}

.properties__caption h3 a,
.properties__caption h3 {
  font-size: 20px;
  font-weight: 500;
  color: #1d1e3c;
  text-decoration: none;
  padding-top: 20px;
  padding-bottom: 10px;
  height: 65px;
}

.rating-count {
  color: #1d1e3c !important;
  font-size: 16px;
  padding: 0px 15px;
}

.rating span {
  color: #9ca3af;
}

.tag {
  position: absolute;
  bottom: -2px;
  margin: 0;
  color: #20776e;
  background-color: #f6f7fb;
  border-radius: 12px;
  padding: 3px 15px;
  left: -2px;
}

.hero_img .animated {
  animation: up-down 2s ease-in-out infinite alternate-reverse both;
}

.section-header-c h2 {
  font-size: 32px;
}

.section-header-c p {
  color: #9ca3af;
  line-height: 38px;
  font-size: 20px;
}

.owl-dots span {
  background-color: #f6f7fb;
  height: 14px !important;
  width: 14px;
  display: inline-block;
  text-align: center;
  border-radius: 100%;
  margin: 0px 6px;
}

.owl-dots {
  text-align: center;
  margin-top: 30px;
}

.slick-dots li button:before {
  font-size: 15px !important;
}

.slick-track {
  display: flex;
  gap: 30px;
}

.slick-dots {
  bottom: -50px !important;
}

.slick-dots li.slick-active button:before {
  color: #007a6e !important;
}

button.owl-dot.active span {
  background-color: #20776e !important;
}

.courses-header {
  margin-bottom: 50px;
  position: relative;
}

.headingwrap p {
  color: #9ca3af;
  line-height: 30px;
}

.courses-wrapper {
  background-color: #fbfbfb;
}

.sorting span {
  position: absolute;
  top: 10px;
  left: 10px;
}

.sorting select {
  border: none;
  appearance: none;
  padding: 10px 10px 10px 40px;
  outline: none;
  border-radius: 7px;
  color: #9ca3af;
  width: 100%;
}

.sorting {
  border: 2px solid rgb(156 163 175 / 55%);
  background-color: #fff;
  position: relative;
  border-radius: 7px;
  margin-top: -80px;
  width: 200px;
}

.headingwrap .item {
  border: 1px solid rgb(156 163 175 / 40%);
  padding: 20px;
  min-height: 232px;
  border-radius: 20px;
}

.member-info h4 {
  margin: 0;
}

.member-info span {
  color: #f2a227;
  font-size: 16px;
}

.member-info {
  padding-top: 5px;
  padding-left: 15px;
}

.pic {
  overflow: hidden;
  width: 60px;
  border-radius: 50%;
}

.item p {
  font-size: 16px;
  font-style: italic;
  color: #9ca3af;
  line-height: 32px;
  padding-top: 20px;
}

.stay-rap {
  background-color: #f6f7fb;
  border-radius: 20px 20px 20px 20px !important;
}

.stay-rap .img img {
  border-radius: 20px 0px 0px 20px !important;
}

.right-img img {
  max-width: 339px;
  margin: 0 auto;
}

.right-img h3 {
  padding: 50px 0px 0px;
}

.right-img p {
  width: 100%;
  padding-top: 10px;
}

.upl-bg input.selectbtn {
  position: absolute;
  left: 0;
}

.right-img .input-group input[type="email"] {
  border: 0 !important;
  background-color: transparent;
  border-bottom: 1px solid #1d1e3c !important;
  border-radius: 0;
  text-align: center;
  box-shadow: none !important;
}

.modal-footer button:hover {
  background-color: #007a6e;
  color: #ffffff;
}

.modal-footer button {
  position: absolute;
  top: 15px;
  border-radius: 100px;
  height: 30px;
  padding: 0;
  width: 30px;
  background-color: rgb(242 162 39 / 37%);
  border: 1px solid #f2a227;
  color: #f2a227 !important;
  font-size: 28px;
  line-height: 20px;
  transition: all ease 0.5s;
}

.course-popup .modal-title span {
  display: block;
  font-size: 18px;
  color: #9ca3af;
}

.modal iframe {
  width: 100%;
  height: 400px;
}

/* .accordion-button:not(.collapsed)::after {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAFCAYAAAB4ka1VAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABgSURBVHgBXYzRDYAwCEQb4x5+soabWCdwBBnBDdRJnAN+jIuAR2KTWpILx+MgpapU9QrVrCtGRA53H0MI7b8AwIY2QdnMZoRyCfW4ZIAFnono/L4NYIz+xHBDa2oqWOxeLB0/v/W2picAAAAASUVORK5CYII=) !important;
  background-size: 12px;
  background-position: center !important;
  transform: rotate(-360deg) !important;
} */

.upl-bg input.selectbtn {
  position: absolute;
  left: 0px;
  background-image: url(images/image-file.png);
  background-repeat: no-repeat;
  background-color: transparent;
  font-size: 0;
  top: 30px;
  left: 30px;
}

.upl-bg .file-name:focus {
  outline: none;
  box-shadow: none;
}

.upl-bg p {
  font-size: 16px;
  color: #20776e;
}

.form-control:focus {
  background-color: #ffffff !important;
}

.upl-bg .file-name {
  text-align: center;
  background-color: transparent;
  border: none !important;
  height: auto;
  margin: 0;
  padding-left: 0;
}

.l-head p {
  color: #9ca3af;
}

.upl-bg .upload-pic .d-flex {
  position: inherit;
}

.img-nocourse {
  padding: 0 0px 50px;
}

.no-padding {
  padding: 0;
}

.initial-date,
.last-date,
.eligibl {
  text-align: center;
  font-size: 13px;
}

.perc {
  color: #f36546;
  text-align: center;
  font-size: 12px;
}

.p-null {
  padding-bottom: 0px !important;
  margin-bottom: -80px;
}

.td.null {
  font-size: 12px;
  text-align: center;
  color: #fcc417;
}

.start-date,
.end-date,
.null {
  font-size: 12px;
  text-align: center;
  color: rgb(52 52 52 / 58%);
}

form h3 {
  color: #3f3f44;
  font-size: 20px;
  margin-bottom: 40px;
  margin-top: 40px !important;
}

.module-header button {
  background-color: #20776e;
  border: none;
  color: #fff;
  height: 40px;
  border-radius: 100px;
  width: 170px;
}

.other-info {
  margin-top: 40px;
  background-color: #fbfbfb;
  padding: 15px 20px;
  border-radius: 10px;
}

section.course-wrapper {
  background-color: #fbfbfb;
}

.module-header button svg {
  margin-right: 10px;
}

.image img {
  max-width: 80px;
}

.image .studentWhoBuyed {
  width: 50px;
  height: 50px;
  border-radius: 10px;
}

.description textarea {
  height: 140px;
  background-color: #f0f0f0;
  border: none;
}

.upl-bg .img-upload {
  padding: 50px;
  border: 2px dashed #00786e;
  border-radius: 10px;
}

.module-header button:hover {
  background-color: orange;
  color: #fff;
}

.upl-bg p b {
  display: block;
}

.upl-bg {
  border-radius: 10px;
  padding: 10px;
  position: relative;
}

/* .accordion-button::after {

  background-image: url(images/down-orange.png) !important;
  background-size: 12px !important;
  background-position: center !important;
} */

button.accordion-button h5 {
  font-weight: bold;
}

.right-img .input-group {
  width: 100%;
  margin: 0 auto;
}

span.input-group-btn button {
  width: 215px;
  height: 48px !important;
  font-size: 16px;
  padding-top: 0px !important;
  color: #fff;
  background-color: #00786e;
  text-align: center;
  line-height: 48px !important;
  text-decoration: none;
  border-radius: 100px;
}

.login_btns .btn {
  border: none;
}

.accordia-wrapper .faq-wrap .outofthree {
  position: absolute;
  right: 30px;
  top: 7px;
  font-size: 14px;
  color: #676767;
}

.accordia-wrapper .accordion-body {
  padding-top: 10px;
}

.project-list a span {
  padding-right: 10px;
}

.project-list strong {
  position: absolute;
  right: 0;
}

.faq-wrap .project-list a span {
  position: absolute;
  left: 0;
  top: 0;
}

.faq-wrap .project-list a {
  color: #b0b0b0;
  font-size: 16px;
  position: relative;
  padding-left: 30px;
}

.accordia-wrapper a.heading-s:after,
.accordia-wrapper a.collapsed:after {
  background-size: 20px;
  background-repeat: no-repeat;
  background-position: 12px 7px;
}

.course-rightbar {
  margin-bottom: 40px;
}

.course-rightbar a {
  display: block;
  border-radius: 25px;
  background-color: #007a6e;
  color: #fff;
  text-decoration: none;
  width: 100%;
  height: 58px;
  line-height: 58px;
  text-align: center;

  transition: all ease 0.5s;
}

.course-rightbar a:hover {
  background-color: #f2a227;
  color: #ffffff;
}

.course-rightbar .green-btn {
  display: block;
  border-radius: 25px;
  background-color: #007a6e;
  color: #fff;
  text-decoration: none;
  width: 100%;
  height: 58px;
  line-height: 58px;
  text-align: center;

  transition: all ease 0.5s;
}

.course-rightbar .green-btn:hover {
  background-color: #f2a227;
  color: #ffffff;
}

.accordia-wrapper a.heading-s:after {
  background-image: url(images/arrow-circle-up.png);
}

.accordia-wrapper a.collapsed:after {
  background-image: url(images/arrow-circle-down.png);
}

a.heading-s:after {
  content: "";
  background-image: url(images/minus-icon.png);
  position: absolute;
  background-size: 35px;
  height: 35px;
  width: 35px;
  right: 0;
  top: 0px;
}

.accordion-flush .accordion-item .accordion-button,
.accordion-flush .accordion-item .accordion-button.collapsed,
.accordion-flush .accordion-item .accordion-button:focus {
  border-radius: 0;
  background-color: transparent;
  outline: none;
  box-shadow: none;
}

a.collapsed:after {
  content: "";
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAkCAYAAADhAJiYAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAB1SURBVHgB7dZBCoAgFATQKbpGnzpHdfzOoXiQfkl7/2YWIvPAneggMnxARGQAZlcyOxIIFhA4fAMmMMzojAJFFCiiQJGwPGrp/T3DuMxzKffe2hO+kIOHeVbTaqfXBQJ96ogCRRQo0l0gysT4dXAGHoiIjOAF6lETvkaEkH4AAAAASUVORK5CYII=);
  position: absolute;
  background-size: 35px;
  height: 35px;
  width: 35px;
  right: 0;
  top: 0px;
}

.for-top {
  padding: 0;
  margin-top: -280px;
}

.blank-bg {
  background-color: #f6f7fb;
  height: 370px;
  border-radius: 20px;
  margin-top: 105px;
  width: 98%;
  margin-left: auto;
  margin-right: auto;
}

.b_answer {
  /* padding-top: 0px; */
}

.faq-wrap a {
  font-size: 24px;
  text-decoration: none;
  color: #1d1e3c;
  font-weight: 300;
  display: block;
  position: relative;
}

span.input-group-btn button:hover {
  background: #f2a227;
  color: #fff;
}

.faq-wrap {
  border: 1px solid #d9d9d9;
  padding: 20px;
  border-radius: 15px;
  margin-bottom: 15px;
}

.right-img .input-group-btn {
  width: 100%;
  margin: 40px 0px 0px 0px;
}

.faq-list {
  padding: 0px 200px 100px;
}

.faq-wrap a:hover {
  color: #007a6e;
}

.video-container {
  margin: 0 auto;
  width: 100%;
}

.minute-course svg {
  margin-right: 10px;
}

.tabs-wrapper {
  position: relative;
}

.tabs-wrapper li.nav-item {
  margin-right: 40px;
}

.tabs-wrapper p {
  line-height: 29px;
  color: #9ca3af;
  font-weight: 300;
}

.nav-tabs .nav-link {
  color: #343434;
}

.icons-tabs {
  position: absolute;
  right: 0;
  top: -4px;
}

.tabs-wrapper .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #007a6e !important;
  border: none;
  position: relative;
}

.tabs-wrapper .nav-tabs .nav-item.show .nav-link:after,
.nav-tabs .nav-link.active:after {
  content: "";
  height: 4px;
  width: 80px;
  background-color: #007a6e;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: -2px;
  border-radius: 10px 10px 0px 0px;
}

.course-description h4 {
  font-size: 20px;
}

.minute-course {
  position: relative;
}

.course-description {
  padding-bottom: 30px;
  border-bottom: 1px solid rgb(240 240 240);
}

.course-description .review span.rating-count {
  padding-left: 0;
}

.review {
  position: relative;
}

.minute-course:before,
.review:before {
  content: "";
  position: absolute;
  left: -20px;
  height: 50px;
  background-color: rgb(204 204 204 / 45%);
  width: 1px;
  top: -10px;
}

.course-description {
  justify-content: space-between !important;
  margin-top: 30px;
}

.js-video {
  width: 100%;
  position: relative;
  margin: 0px auto;
}

.play i {
  position: absolute;
  left: 45%;
  /* top: 50%; */
  color: #ffc107;
  font-size: 59px;
  background: #fff;
  border-radius: 50%;
  height: 90px;
  width: 90px;
  line-height: 93px;
  z-index: 2;
}

.play i:before {
  margin-left: 8px;
}

.video-poster img {
  height: 100%;
  object-fit: cover;
  width: 100%;
  border-radius: 10px;
}

/* .video-poster{
		background: url('images/videothumb.jpg');
    background-repeat: no-repeat;
    position: absolute;
    width: 100%;
    height: 439px;
    background-size: cover;
    border-radius: 20px;
} */
.play {
  position: absolute;
  top: 40%;
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
}

.play i:hover {
  cursor: pointer;
}

.accordia-wrapper .faq-wrap {
  background-color: #fff;
  padding: 15px 20px;
}

.js-video iframe {
  z-index: 5;
  position: relative;
  border-radius: 15px;
}

.search-wrap input[type="search"]:focus {
  outline: none;
  box-shadow: none;
}

.search-wrap input[type="search"] {
  padding-left: 65px;
  height: 60px;
  border: none;
  border-radius: 20px;
}

.search-wrap button {
  position: absolute;
  top: 13px;
  background-color: transparent;
  border: none;
  outline: none;
  padding: 0;
  left: 20px;
}

.pagination-wrap {
  background-color: #fbfbfb;
  padding: 0px;
}

.pagination .active .page-link {
  background-color: #20776e;
  border: #20776e;
  color: #ffffff;
}

.page-item .page-link i {
  font-size: 27px;
  color: #ccc;
}

.pagination li a {
  border-radius: 23px;
  margin: 0px 7px;
  height: 60px;
  width: 60px;
  border: 1px solid #a098ae;
  line-height: 60px;
  padding: 0;
  text-align: center;
  color: #20776e;
  margin: 0 14px;
}

.pagination li a:focus {
  box-shadow: none !important;
  color: #00786e;
}

.pagination li:first-child a,
.pagination li:last-child a,
.pagination li:first-child a:focus,
.pagination li:last-child a:focus {
  border: none;
  box-shadow: none !important;
  background-color: transparent;
  outline: none;
}

.search-wrap button i {
  color: #20776e;
  line-height: 57px;
  font-size: 26px;
}

.search-wrap button:hover,
.search-wrap button:focus {
  background-color: transparent !important;
  border: none !important;
}

.search-wrap {
  position: relative;
}

.search-wrap {
  padding-bottom: 100px;
}

.rating-user input[type="checkbox"][id^="myCheckbox"] {
  display: none;
}

.rating-user label {
  display: inline-block;
  position: relative;
  margin: 10px;
  cursor: pointer;
}

button.btn.watch_btn {
  transition: all ease 0.5s;
}

.feedback-container {
  background-color: #fff;
  padding: 80px;
  border-radius: 30px;
  border: 1px solid #f0f0f0;
  text-align: center;
}

.rating-user ul li i {
  font-size: 34px;
}

.rating-user ul li span {
  display: block;
  width: 80px;
  font-size: 17px;
  text-align: center;
  margin-top: 5px;
}

.rating-user ul li {
  display: block;
  text-align: center;
}

.rating-user ul {
  display: flex;
  justify-content: space-around;
}

.rating-user label:before {
  color: #ffc007;
  content: "\F586";
  display: block;
  border-radius: 50%;
  position: absolute;
  top: 11px;
  left: 23px;
  width: 34px;
  height: 34px;
  text-align: center;
  line-height: 28px;
  transition-duration: 0.4s;
  transform: scale(0);
  font-family: bootstrap-icons !important;
}

.single {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  /* You can adjust the width as needed */
  border: 1px solid #ccc;
  padding: 5px;
  display: inline-block;
  /* Ensures the label width is based on its content */
}

.links-list {
  justify-content: center;
  gap: 15px;
  margin-top: 20px;
}

.rating-user label img {
  height: 100px;
  width: 100px;
  transition-duration: 0.2s;
  transform-origin: 50% 50%;
  filter: grayscale(100%);
}

.rating-user input:checked+label {
  border-color: #ddd;
}

.rating-user input:checked+label:before {
  content: "\F586";
  transform: scale(1);
  font-size: 34px;
  z-index: 1;
  font-family: bootstrap-icons !important;
}

.rating-user input:checked+label img {
  transform: scale(0.9);
  z-index: -1;
  border: 1px solid #64ba83;
  filter: none;
}

.feedback-container p {
  color: #666666;
  line-height: 32px;
  margin: 20px auto 40px;
}

.swiper.swiper-initialized {
  padding-bottom: 60px;
}

.swiper-button-next,
.swiper-button-prev {
  bottom: 0px;
}

.form-group {
  margin-bottom: 35px;
}

.icons-tabs a {
  margin: 0px 4px;
  color: rgba(240, 240, 240, 1);
}

.icons-tabs a:hover {
  color: #00786e;
}

input.form-control,
select.form-select,
.file-name {
  height: 56px;
  border: none;
  font-size: 16px;
  padding-left: 20px;
  margin-top: 10px;
  border: 1px solid #f1f0f0 !important;
  width: 100%;
}

.margin_mobile .form-control {
  margin-top: 0 !important;
  margin-bottom: 15px !important;
}

/*sanjay changes*/
.myc1 {
  height: 56px;
  border: none;
  font-size: 16px;
  padding-left: 20px;
  margin-top: 10px;
  border: 1px solid #8b0c0c !important;
  width: 100%;
}

.myc2 {
  height: 56px;
  border: none;
  font-size: 16px;
  padding-left: 20px;
  margin-top: 10px;
  border: 1px solid #8b0c0c !important;
  width: 100%;
}

.myc3 {
  height: 56px;
  border: none;
  font-size: 16px;
  padding-left: 20px;
  margin-top: 10px;
  border: 1px solid #8b0c0c !important;
  width: 100%;
}

.mypagenation {
  display: flex;
  list-style-type: none;
  gap: 10px;
  justify-content: center;
  align-items: center;
  width: auto !important;
  margin: auto;
}

.mypagenation li {
  border: 1px solid #a098ae;
  padding: 5px 14px;
  border-radius: 5px;
}

.mypagenation li.selected {
  background-color: #20776e;
  border-color: #20776e;
  color: white;
}

.mypagenation li.previous {
  border: none;
}

.mypagenation li.next {
  border: none;
}

.login textarea.myc2 {
  border: 1px solid #8b0c0c !important;
}

.login h1 {
  font-weight: normal;
  margin-bottom: 30px;
}

.input-group-addon i {
  color: rgb(63 63 68 / 62%);
  font-size: 24px;
}

.input-group-addon {
  position: absolute;
  right: 0;
  top: 20px;
  right: 20px;
  z-index: 9;
}

.login textarea {
  font-size: 16px;
  padding-left: 20px;
  margin-top: 10px;
  border-radius: 0px;
  border: 1px solid #ffffff !important;
}

.r-head button {
  width: 300px;
  border-radius: 25px;
  background-color: #20776e;
  border: 1px solid #20776e;
  height: 60px;
  line-height: 60px;
  padding: 0;
  font-size: 18px;
  margin-top: 20px;
  transition: all ease 0.5s;
  margin-left: 0px;
}

.login .form-group button {
  width: 300px;
  border-radius: 25px;
  background-color: #20776e;
  border: 1px solid #20776e;
  height: 60px;
  line-height: 60px;
  padding: 0;
  font-size: 18px;
  margin-top: 20px;
  transition: all ease 0.5s;
  margin-left: 0px;
}

.login .form-group button:hover {
  background-color: #f2a227;
  color: #fff;
  border: 1px solid #f2a227;
}

.file {
  opacity: 0;
}

.upload-pic .d-flex {
  position: relative;
}

.file-name:focus-visible {
  box-shadow: none;
  border: none !important;
}

input.selectbtn {
  position: absolute;
  right: 0;
  height: 57px;
  width: 120px;
  top: 10px;
  background-color: #007a6e;
  border: none;
  border-radius: 0px 10px 10px 0px;
  color: #fff;
  text-transform: uppercase;
}

.login .file-name {
  position: relative;
  overflow: hidden;
  line-height: 30px;
  padding: 5px;
  box-sizing: border-box;
  font-size: 15px;
  vertical-align: middle;
  width: 100%;
  border-radius: 0;
  padding-left: 15px;
}

.login .btn {
  background: #3479ce;
  border: none;
  border-radius: 0;
  width: 100px;
  box-sizing: border-box;
  padding: 2px 10px;
  background-color: #4493c7;
  transition: all 0.6s;
  color: #fff;
  font-size: 15px;
  vertical-align: middle;
  text-transform: uppercase;
  margin-left: 1rem;
}

.login .btn:hover {
  background: #2a64ad;
  box-shadow: 0 0 5px #33006e;
}

select.form-select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url(images/arrow-select.png) 96% / 15% no-repeat #fff !important;
  background-size: 13px !important;
  border: 2px solid #f0f0f0;
}

.video-btn button {
  background-color: #fff;
  border: 1px solid #20776e;
  color: #20776e;
  height: 50px;
  width: 143px;
  border-radius: 10px;
  line-height: 50px;
  padding: 0;
}

input:focus,
textarea:focus {
  outline: none !important;
  box-shadow: none !important;
}

.video-btn button:hover {
  opacity: 0.8;
}

.strt-end input {
  margin: 0;
}

.video-btn button svg {
  margin-right: 5px;
}

select.form-select:focus {
  box-shadow: none;
  outline: none !important;
}

.Module {
  background-color: #fff;
  padding: 10px 30px !important;
  margin-bottom: 25px;
  border-radius: 10px;
}

.module-header {
  margin-bottom: 20px;
}

.s-t input,
.e-t input {
  height: 50px;
  border: none;
  background-color: #f0f0f0;
  border-radius: 10px;
  padding-left: 10px;
  font-size: 13px;
}

.inp-title input {
  height: 50px;
  border: none;
  background-color: #f0f0f0;
  width: 100%;
  border-radius: 10px;
  padding-left: 10px;
  font-size: 13px;
}

.video-btn {
  align-items: flex-end;
  display: flex;
  justify-content: end;
}

.inp-title span {
  font-size: 13px;
  color: #3f3f44;
  padding-right: 20px;
  display: inline-block;
}

.module-c h6 {
  margin: 0;
  color: #b0b0b0;
  font-size: 13px;
}

.module-header h5 {
  margin: 0;
}

.description {
  margin-top: 30px !important;
  margin-bottom: 15px;
}

.module-header button svg {
  width: 22px;
  height: 30px;
}

.chapter-add button {
  border: none;
  background-color: transparent;
  padding: 0;
  color: #00786e;
  font-size: 16px;
  font-weight: bold;
}

.guide-img img {
  border-radius: 10px !important;
}

.guide-text p {
  margin: 0;
  padding-left: 15px;
}

.guide .guide-li {
  margin-bottom: 20px;
}

span.icon-v {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  background-color: #fff;
  width: 30px;
  text-align: center;
  border-radius: 100%;
  height: 30px;
  line-height: 30px;
  top: 50%;
  transform: translatey(-50%);
}

.chapter-add button:hover {
  color: orange;
}

.chapter-add button svg {
  margin-right: 5px;
}

.module-wrapper {
  margin-bottom: 40px;
}

.guide-img {
  position: relative;
}

.next button {
  background-color: #20776e;
  border: none;
  color: #fff;
  height: 40px;
  border-radius: 100px;
  width: 167px;
  margin-top: 20px;
}

.submitter-l h4 {
  font-size: 14px;
  color: #3f3f44;
}

.submitter-l span {
  color: rgb(63 63 68 / 51%);
  font-weight: lighter;
  font-size: 15px;
}

.pub-text button {
  background-color: rgb(252 196 23 / 45%);
  border: none;
  color: #222222;
  border-radius: 10px;
  padding: 2px 15px;
  font-size: 12px;
  line-height: 12px;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  line-height: 1.5;
  overflow: hidden;
  text-overflow: ellipsis;
}

.guide h3 {
  margin-bottom: 30px;
  font-size: 24px;
}

.last-submitted {
  margin-top: 60px;
}

.quiz-header p {
  color: #9ca3af;
}

.quiz-wrapper h4 {
  margin-bottom: 30px;
  font-size: 16px;
  color: #b0b0b0;
}

.hero__btns .sub-review {
  color: #fff;
  background-color: #20776e;
}

.hero__btns button:hover {
  background-color: #ffa500;
  color: #fff;
  border-color: #ffa500;
}

.hero__btns button {
  display: inline-block;
  border: 1px solid #20776e;
  text-decoration: none;
  height: 60px;
  border-radius: 15px;
  color: #20776e;
  margin-left: 20px;
  width: 190px;
  text-align: center;
  line-height: 60px;
  font-size: 18px;
}

.quiz-wrapper {
  background-color: #ffffff;
  padding: 30px;
  margin-top: 30px;
  border-radius: 15px;
}

.sidebar {
  /* padding-left: 70px !important; */
}

.frogot {
  text-align: right;
  margin-top: -30px;
}

.frogot a {
  font-size: 16px;
  text-decoration: none;
  color: #3f3f44 !important;
}

.image_preview {
  position: relative;
}

.uploader input {
  opacity: 0;
  width: 100px;
}

.preview_image img {
  border-radius: 10px;
  width: 250px;
  height: 340px;
  object-fit: cover;
}

.preview_image {
  margin: 0 auto;
  text-align: center;
  width: auto;
}

.message-g span.input-group-addon {
  background-color: transparent;
  border: navajowhite;
  position: absolute;
  bottom: 10px;
  top: auto;
  right: 0;
}

.message-g {
  margin-top: 50px;
}

.uploader {
  position: absolute;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  bottom: -30px;
  margin: auto;
  display: block;
  text-align: center;
  left: 0;
  right: 0;
  background: #fff;
}

.message-g {
  position: relative;
}

.feedback-container textarea {
  height: 169px;
  border: none;
  background-color: #f6f6f6;
  padding: 15px;
}

.c-listing button:after {
  content: none;
}

.c-listing button {
  border: none;
  background-color: transparent;
}

.c-listing .user-rating {
  padding-left: 0 !important;
}

.certified {
  /* background-color: rgb(61 184 50 / 12%) !important; */
  color: #3db832;
  padding: 4px 0px !important;
  border-radius: 10px;
  font-size: 12px;
  font-weight: normal;
  /* margin-right: 45px; */
}

.n-certified {
  color: #f36546;
  padding: 4px 10px !important;
  border-radius: 10px;
  font-weight: normal;
  font-size: 12px;
}

.uploader svg {
  margin-top: -24px;
}

.table {
  display: table;
  border-collapse: collapse;
}

.table .tr {
  display: table-row;
  border: none;
}

.table .tr:first-child {
  font-weight: 400;
  border-bottom: none;
  background-color: #f6f6f6;
}

.table .tr:first-child .td {
  border-left: 0;
  color: rgb(63 63 68 / 50%);
}

.c-listing .title {
  font-size: 20px;
  margin-bottom: 30px;
  color: #3f3f44;
  font-weight: bold;
}

a.take_quiz span.label.certified {
  width: 200px !important;
  display: inline-block;
  text-align: left;
  background-color: transparent !important;
  color: #ff8a00;
  font-weight: bold;
  text-decoration: underline;
  padding-left: 0 !important;
}

.table .tr .td {
  display: table-cell;
  padding: 8px !important;
  border-left: none;
  /* text-align: center; */
}

.table .tr .td:first-child {
  border-left: 0;
}

/* input type radion */

/* change in display property */
.question-wrapp input[type="radio"] {
  display: none;
}

.question-wrapp .form-group {
  border: 1px solid #ccc;
  padding: 15px 40px;
  border-radius: 15px;
}

.question-wrapp label {
  cursor: pointer;
  position: relative;
  font-size: 16px;
}

.question-wrapp label::before {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: transparent;
  border: 2px solid rgb(206, 206, 206);
  border-radius: 50%;
  top: 50%;
  left: -30px;
  transform: translateY(-50%);
  transition: border-color 400ms ease;
}

.question-wrapp label::after {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: #20776e;
  border: 2px solid #20776e;
  border-radius: 50%;
  top: 50%;
  left: -30px;
  transform: translateY(-50%) scale(0);
  transition: transform 400ms ease;
}

.question-wrapp input[type="radio"]:checked+label::before {
  border-color: #20776e;
}

.question-wrapp input[type="radio"]:checked+label::after {
  transform: translateY(-50%) scale(0.55);
}

.question-wrapp input[type="checkbox"]:checked+label::before {
  border-color: #20776e;
}

.question-wrapp input[type="checkbox"] {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.question-wrapp input[type="checkbox"]:checked+label::after {
  transform: translateY(-50%) scale(0.55);
}

.question-wrapp1 input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.question-wrapp1 .form-group label {
  position: relative;
  cursor: pointer;
}

.question-wrapp1 .form-group label:before {
  content: "";
  -webkit-appearance: none;
  background-color: transparent;
  border: 2px solid #c2c5c7;
  box-shadow: 0 1px 2px rgb(0 0 0 / 5%),
    inset 0px -15px 10px -12px rgb(0 0 0 / 5%);
  padding: 8px;
  display: inline-block;
  position: relative;
  border-radius: 100%;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 5px;
}

.question-wrapp1 .form-group input:checked+label:after {
  content: "";
  display: block;
  position: absolute;
  top: 7px;
  left: 4px;
  border: solid #20776e;
  border-width: 6px;
  transform: rotate(45deg);
  border-radius: 100%;
}

.question-wrapp1 .form-group {
  border: 1px solid #ccc;
  padding: 15px 12px;
  border-radius: 10px;
}

.teacher-profile-icon {
  width: 50px;
  height: 50px !important;
  object-fit: cover;
  border-radius: 50%;
}

.nav-dropdown button {
  border: none;
  background-color: transparent;
}

.nav-dropdown .dropdown-toggle::after {
  content: none !important;
}

.nav-dropdown button.show:focus {
  border: none !important;
  box-shadow: none;
}

.nav-dropdown img {
  max-width: 50px;
  min-height: 50px;
  width: 50px;
  border-radius: 100%;
  height: 50px;
  object-fit: cover;
  margin-left: 10px;
  border: 1px solid #f0f0f0;
}

.nav-dropdown ul.dropdown-menu li {
  padding: 0px 0px;
}

.nav-dropdown ul.dropdown-menu li:last-child a {
  border-bottom: 0;
}

.nav-dropdown button.dropdown-toggle {
  transition: all ease 0.5s;
  text-transform: capitalize;
}

.nav-dropdown button.dropdown-toggle.show svg {
  transform: rotate(-180deg);
}

.nav-dropdown ul.dropdown-menu li:last-child a {
  color: #fe981f;
}

.nav-dropdown ul.dropdown-menu li a {
  border-bottom: 1px solid rgb(204 204 204 / 44%);
  padding: 6px 0;
  text-align: center;
}

.nav-dropdown ul.dropdown-menu.show {
  width: 200px;
  padding: 0px;
}

.nav-dropdown button.show:focus-visible {
  border: none !important;
  box-shadow: none !important;
}

.question-wrapp .form-group {
  position: relative;
}

.question-wrapp input[type="text"] {
  width: 77%;
  position: absolute;
  border: none;
  margin-left: 28px;
  left: 40px;
  height: 54px;
  top: 0;
  background: transparent;
  line-height: 54px;
}

.upload-pic.upl-bg img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.upload-pic.upl-bg {
  margin-top: 35px;
}

.upload-pic.upl-bg input {
  height: 100% !important;
}

.vide {
  height: 0;
  width: 0;
  opacity: 0;
}

.t-profile h3 {
  font-size: 18px;
  margin-top: 20px;
}

/* closed input type radioo */
.t-wrapper {
  margin-top: 140px;
}

.video-poster video {
  /* height: 100%; */
  width: 100% !important;
}

.faq-wrap a {
  font-size: 18px;
  padding-right: 70px !important;
}

.accordion-header button {
  padding: 0;
}

.accordia-wrapper .accordion-body {
  padding-top: 0;
  padding-left: 0;
}

.course-rightbar {
  margin-bottom: 0;
}

.course-rightbar a {
  margin-bottom: 20px;
}

.course-rightbar a {
  color: #fff !important;
}

.accordia-wrapper .faq-wrap .accordion-item {
  margin-bottom: 20px;
  padding-bottom: 20px;
}

.accordion-flush .accordion-item:last-child {
  margin-bottom: 0;
}

.accordia-wrapper .accordion-flush .accordion-item:last-child {
  padding-bottom: 0;
}

@media (min-width: 992px) {
  .search-wrap {
    width: 638px;
  }

  .feedback-container p {
    width: 480px;
  }

  .p-l {
    padding-left: 60px;
    padding-top: 120px;
  }
}

@media (min-width: 1280px) {
  .section-header-c p {
    max-width: 700px;
  }

  .right-img .input-group {
    width: 60%;
  }

  .headingwrap .section-header {
    text-align: center;
    width: 790px;
    margin: 0 auto;
  }

  .footer-info {
    width: 339px;
  }
}

@media (max-width: 1280px) {
  /* .login_btns {
    width: 630px;
    display: flex;
  } */

  .realestate li {
    margin: 0 20px;
  }

  .realestate li:after {
    content: none !important;
  }

  ul.list-inline.realestate {
    display: block !important;
    padding: 0px 50px;
    flex: none !important;
    text-align: center;
  }
}

@media (max-width: 1279px) {
  i.bi.bi-list.mobile-nav-toggle.d-none {
    display: block !important;
  }

  .mobile-nav-active i.bi.mobile-nav-toggle.d-none.bi-x {
    display: block !important;
  }

  .mobile-nav-active .navbar:before {
    background-color: #ffffff;
  }

  .mobile-nav-active .navbar {
    left: 0;
    height: 600px !important;
    width: 100%;
  }

  li.dropdown {
    padding-left: 20px;
  }

  .mobile-nav-active .navbar li {
    list-style-type: none;
  }

  body.mobile-nav-active .container-fluid.d-flex.align-items-center {
    display: block !important;
    text-align: left;
  }
}

@media (max-width: 1200px) {
  section.courses-wrapper .courses-header {
    flex: none !important;
    display: block !important;
  }

  .sorting {
    margin: 0;
  }
}

@media (max-width: 992px) {
  .faq-list {
    padding: 0px 10px 20px;
  }

  a.collapsed:after {
    top: -5px;
  }

  ul.list-inline.realestate li {
    margin: 0px 20px;
  }

  .preview_image img {
    width: 250px;
    height: 200px;
  }

  .hero {
    padding: 100px 0 60px 0 !important;
  }

  .section-header-c h2 {
    font-size: 28px;
  }

  .namecour span {
    font-size: 15px;
    padding-left: 2px;
  }

  .namecour img {
    width: 40px !important;
    height: 40px !important;
  }

  .realestate li {
    font-size: 16px;
    margin-bottom: 15px !important;
  }

  section {
    padding: 60px 0;
  }

  .counter {
    margin-top: 80px;
    gap: 40px;
  }

  .learning p,
  .instructor p {
    width: 100%;
  }

  .properties__card {
    padding: 15px;
  }

  .nav-dropdown img {
    width: 40px;
    height: 40px;
    margin-left: 5px;
    border: 1px solid #f0f0f0;
  }

  .b-btn {
    font-size: 14px;
  }

  .r-head button {
    width: auto;
  }

  .l-head h1 {
    font-size: 25px;
  }

  .hero_btns .green_btn {
    font-size: 14px;
    margin-right: 5px;
  }
}

@media (max-width: 767px) {

  .container,
  .container-sm {
    /* max-width: 100% !important; */
  }

  .hero h1 {
    font-size: 35px;
  }

  .form-group.row {
    /* margin-bottom: 0; */
  }

  .form-group.row .col-lg-6 {
    margin-bottom: 20px;
  }

  .section-header h2 {
    font-size: 32px;
    margin-bottom: 0px;
  }

  .courses-wrapper .col-lg-4 {
    margin-bottom: 20px;
  }

  .row.stay-rap .content {
    padding: 30px 15px;
  }

  .right-img img {
    max-width: 275px;
  }

  .course-description.d-flex.align-items-center {
    display: block !important;
    margin-bottom: 40px !important;
  }

  .course-description.d-flex.align-items-center div {
    margin-bottom: 15px;
  }
}

@media (max-width: 640px) {
  .col-lg-12.login .d-flex {
    display: block !important;
    flex: none !important;
    text-align: center;
  }

  .login_btns button#defaultDropdown {
    position: absolute;
    bottom: -40px;
    right: 0px;
  }

  .header .logo img {
    max-height: 30px;
    margin-right: 6px;
  }

  .hero h1 {
    font-size: 30px;
  }

  .hero_img {
    text-align: center;
    margin-bottom: 40px;
  }

  .hero_img img {
    max-width: 80%;
  }

  .image_preview {
    margin-bottom: 50px;
  }

  ul.list-inline.realestate {
    padding: 0px 0px;
  }
}

@media (max-width: 540px) {
  .hero h1 {
    font-size: 28px;
  }
}

.cssload-loader {
  width: 244px;
  height: 49px;
  line-height: 49px;
  text-align: center;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  font-family: helvetica, arial, sans-serif;
  text-transform: uppercase;
  font-weight: 900;
  font-size: 18px;
  color: #20776e;
  letter-spacing: 0.2em;
}

.cssload-loader::before,
.cssload-loader::after {
  content: "";
  display: block;
  width: 15px;
  height: 15px;
  background: #20776e;
  position: absolute;
  animation: cssload-load 0.81s infinite alternate ease-in-out;
  -o-animation: cssload-load 0.81s infinite alternate ease-in-out;
  -ms-animation: cssload-load 0.81s infinite alternate ease-in-out;
  -webkit-animation: cssload-load 0.81s infinite alternate ease-in-out;
  -moz-animation: cssload-load 0.81s infinite alternate ease-in-out;
}

.cssload-loader::before {
  top: 0;
}

.swiper-pagination-bullet-active {
  background-color: #20776e;
}

.swiper-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet,
.swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
  height: 12px;
  width: 12px;
}

.cssload-loader::after {
  bottom: 0;
}

@keyframes cssload-load {
  0% {
    left: 0;
    height: 29px;
    width: 15px;
  }

  50% {
    height: 8px;
    width: 39px;
  }

  100% {
    left: 229px;
    height: 29px;
    width: 15px;
  }
}

@-o-keyframes cssload-load {
  0% {
    left: 0;
    height: 29px;
    width: 15px;
  }

  50% {
    height: 8px;
    width: 39px;
  }

  100% {
    left: 229px;
    height: 29px;
    width: 15px;
  }
}

@-ms-keyframes cssload-load {
  0% {
    left: 0;
    height: 29px;
    width: 15px;
  }

  50% {
    height: 8px;
    width: 39px;
  }

  100% {
    left: 229px;
    height: 29px;
    width: 15px;
  }
}

@-webkit-keyframes cssload-load {
  0% {
    left: 0;
    height: 29px;
    width: 15px;
  }

  50% {
    height: 8px;
    width: 39px;
  }

  100% {
    left: 229px;
    height: 29px;
    width: 15px;
  }
}

@-moz-keyframes cssload-load {
  0% {
    left: 0;
    height: 29px;
    width: 15px;
  }

  50% {
    height: 8px;
    width: 39px;
  }

  100% {
    left: 229px;
    height: 29px;
    width: 15px;
  }
}


.testimonials-section {
  display: flex;
  flex-direction: row;
  margin-top: 90px;
  gap: 30px;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 80px;
}

.testimonial-card {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  padding: 30px 30px 30px 30px;
  height: 250px;
  box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
  background-color: white;
  border-radius: 20px;
  width: 480px;
}



.testimonial-card .title {
  color: #1d1e3c;
  font-family: "DM Sans";
  font-size: 14px;
  font-style: normal;
  line-height: 0px;
  font-weight: 700;
  text-transform: capitalize;
}

.testimonial-card .para {
  color: #9ca3af;
  font-family: "DM Sans";
  font-size: 14px;
  font-style: italic;
  margin-top: 10px;
  font-weight: 400;
  line-height: 20px;
}

.testimonial-card .user-image {
  width: 40px;
  height: 40px;
  border-radius: 50px;
}

.testimonial-card .username {
  color: #1d1e3c;
  font-family: "DM Sans";
  font-size: 13px;
  font-style: normal;
  line-height: 0px;
  font-weight: 500;
  text-transform: capitalize;
}